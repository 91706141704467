#root {
	overflow-x: hidden;
	// min-height: 100vh;
}
.landPage {
	background-image: url("../../../assets/images/LandPage/sec1.png");
	[dir="rtl"] & {
		background-image: url("../../../assets/images/LandPage/bgArabic.png");
		width: 100%;
	}
	min-height: 100vh;
	background-repeat: no-repeat;
	position: relative;
	background-size: cover;

	.safa-logo {
		width: 239px;
	}

	.btn-landPage {
		background: transparent linear-gradient(180deg, #b58236 0%, #cb9a51 100%) 0%
			0% no-repeat padding-box;
		color: white;
		display: inline-block;
		font-weight: 400;
		text-align: center;
		width: 100%;
		vertical-align: middle;
		-webkit-user-select: none;
		user-select: none;
		background-color: transparent;
		border: 1px solid transparent;
		padding: 0.375rem 2.95rem;
		font-size: 1rem;
		line-height: 1.5;
		transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
			border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	}
	.sec1 {
		display: flex;
		//height: 70vh !important;
		height: 94vh !important;

		flex-direction: column;
		flex-wrap: wrap;
		justify-content: center;
		h2 {
			font-size: 30px;
			color: #000000;
			margin-bottom: 15px;
			font-family: sans-serif;
			[dir="rtl"] & {
				font-family: "Almarai", sans-serif;
			}
		}
		h3 {
			font-size: 60px;
			max-width: 38%;
			font-weight: 700;
			color: #0c3b5c;
			line-height: 0.9;
			margin-bottom: 25px;
			font-family: sans-serif;
			letter-spacing: -1.5px;
			[dir="rtl"] & {
				font-family: "Almarai", sans-serif;
			}
		}
	}
	.box-item-home {
		background-color: #fff;
		margin: 0 20px 0 0;
		height: 60px;
		display: flex;
		align-items: center;
		font-size: 22px;
		color: #000;
		padding: 10px;
		font-weight: 700;
		[dir="rtl"] & {
			font-family: "Almarai", sans-serif;
		}
	}
	.home-input-containt {
		position: relative;
		width: 50%;
		margin-top: 30px;
		margin-bottom: 35px;
		input {
			height: 80px;
			border: none;
			padding: 0 25px;
		}
		button {
			position: absolute;
			[dir="ltr"] & {
				right: 10px;
			}
			top: 15px;
			height: 55px;
			border-radius: 0;
			font-size: 16px;
			[dir="rtl"] & {
				font-family: "Almarai", sans-serif;
				font-size: 21px;
				left: 10px;
			}
		}
	}
	.home-list {
		gap: 35px;
		margin-top: 35px;
		.list-item-home {
			gap: 5px;
			align-items: center;
			span {
				color: #0c3b5c;
				font-size: 19px !important;

				[dir="rtl"] & {
					font-family: "Almarai", sans-serif;
				}
			}

			.list-item-home-icon {
				width: 18px;
				height: 18px;
				background: #cb9a51;
				border-radius: 50%;
				display: flex;
				align-items: center;
				justify-content: center;
				i {
					color: #fff;
					font-size: 11px;
				}
			}
		}
	}
}

.sec2 {
	// background-image: url("../../../assets/images/LandPage/sec2Line.png");
	// background-repeat: no-repeat;
	// background-size: cover;
	// background-position: top;
	position: relative;
	* {
		[dir="rtl"] & {
			font-family: "Almarai", sans-serif;
		}
	}

	.header-sec2 {
		position: absolute;
		top: 0;
		margin-top: 25px;
		z-index: 65;

		p {
			color: #cb9a51;
			font-size: 19px;
			margin-bottom: 10px;
			[dir="rtl"] & {
				font-family: "Almarai", sans-serif;
			}
		}
		h2 {
			font-size: 50px;
			font-weight: 700;
			color: #0c3b5c;
			line-height: 0.9;
			margin-bottom: 25px;
			font-family: sans-serif;
			letter-spacing: -1.5px;
			[dir="rtl"] & {
				font-family: "Almarai", sans-serif;
				width: 60%;
				font-size: 35px;
				line-height: 1.2;
			}
		}
		div {
			span {
				font-size: 20px;
				font-weight: 700;
				color: #2d2d2d;
			}
			p {
				color: #2d2d2d;
				[dir="rtl"] & {
					font-family: "Almarai", sans-serif;
					width: 55% !important;
				}
			}
			button {
				padding: 10px 35px;
				border-radius: 0 !important ;
				[dir="rtl"] & {
					font-family: "Almarai", sans-serif;
					padding: 10px 45px;
					font-size: 18px;
				}
			}
		}
	}
	.containt-sec2 {
		position: relative;
		//top: 391px;

		img {
			width: 100% !important;
			[dir="rtl"] & {
				//transform: rotate(180deg);
			}
		}

		.sec2-box {
			gap: 135px;
			position: absolute;
			bottom: 0%;
			width: 100%;
			left: 12%;
			[dir="rtl"] & {
				right: 12%;
				top: 55%;
			}

			.sec2-box-item {
				position: relative;
				transition: all 0.5s ease-in-out !important;
				// top: 95px;
				.bg-box {
					position: absolute;
					right: 27%;
					top: 0%;
					[dir="rtl"] & {
						right: 46%;
						top: 10px;
					}

					span {
						font-size: 190px;
						color: #f2f2f2;
						opacity: 0.5;
						[dir="rtl"] & {
							font-family: "Almarai", sans-serif;
						}
					}
				}
				h3 {
					color: #0c3b5c;
					font-size: 22px;
					font-weight: 800;
					font-family: sans-serif;
					[dir="rtl"] & {
						font-family: "Almarai", sans-serif;
					}
				}
				p {
					max-width: 70%;
					[dir="rtl"] & {
						font-family: "Almarai", sans-serif;
					}
				}
				.dot {
					background-color: #c4c4c4;
					width: 50px;
					height: 50px;
					border-radius: 50%;
					border: 15px solid #fff;
					margin-bottom: 25px;
					[dir="rtl"] & {
						margin-bottom: 29px;
					}
				}
			}
			.sec2-box-item:hover {
				h3 {
					color: #cb9a51;
				}
				.dot {
					background-color: #cb9a51;
				}
			}
			.sec2-box-item:first-of-type {
				top: 156px;
			}
			.sec2-box-item:nth-child(2) {
				bottom: 41px;
			}

			.sec2-box-item:last-of-type {
				bottom: 307px;
				[dir="rtl"] & {
					bottom: 348px;
				}
			}
		}
	}
}
.container-custom-land {
	max-width: 1470px;
	margin: auto !important;
}

.form-landing {
	display: flex;
	align-items: center;
	justify-content: center;
	* {
		[dir="rtl"] & {
			font-family: "Almarai", sans-serif;
		}
	}
	background: linear-gradient(
		90deg,
		rgba(240, 244, 247, 1) 0%,
		rgba(255, 255, 255, 1) 35%
	);
	//background: rgba(240, 244, 247, .3);
	.bg-img {
		background-image: url("../../../assets/images/LandPage/bgForm.png") !important;
		background-repeat: no-repeat;
		background-size: cover;
		width: 100% !important;
	}
	.container-form {
		width: 800px;
		margin: auto;
	}

	h2 {
		color: #0c3b5c;
		font-size: 30px;
		font-weight: 700;
		font-family: sans-serif;
	}
	p {
		color: #949595;
		font-size: 19px;
		font-family: sans-serif;
	}
	.btn-yellow {
		border-radius: 0 !important;
		[dir="rtl"] & {
			font-size: 18px;
		}
	}
}
.footer {
	border: 1px solid #e5e5ea;
	display: flex;
	justify-content: center;
	align-items: center;
	p {
		color: #181433;
		font-size: 16px;
		font-family: sans-serif;
		span {
			font-size: 25px;
			vertical-align: middle;
		}
		bold {
			color: #cb9a51;
		}
	}
}

@media (min-width: 1024px) and (max-width: 1470px) {
	.containt-sec2 {
		//width: 98% !important;
		//margin: auto !important;
		//margin: 20px 23px 0 0;
	}
	.container-custom-land {
		//max-width: 1415px !important;
		.header-sec2 {
			div {
				p {
					width: 55% !important;
				}
			}
		}
		.sec2-box-item:nth-child(1) {
			[dir="rtl"] & {
				top: 130px !important;
			}
		}

		.sec2-box-item:nth-child(2) {
			[dir="rtl"] & {
				top: unset !important;
				bottom: 36px !important;
			}
		}
		.sec2-box-item:nth-child(3) {
			[dir="rtl"] & {
				bottom: 213px !important;
			}
		}
	}
}

@media (min-width: 800px) and (max-width: 1025px) {
}

@media (min-width: 1481px) and (max-width: 1540px) {
	.container-custom-land {
		max-width: 1415px !important;
		.sec2-box-item:nth-child(2) {
			bottom: 33px !important;
		}
	}
}
@media (min-width: 1381px) and (max-width: 1480px) {
	.container-custom-land {
		max-width: 1390px !important;
	}
	.sec2-box-item {
		width: 22%;
	}
	.sec2-box-item:first-of-type {
		top: 207px !important;
	}
	.sec2-box-item:nth-child(2) {
		top: 20px !important;
	}
	.sec2-box-item:last-of-type {
		bottom: 116px !important;
	}
}
@media (min-width: 1275px) and (max-width: 1380px) {
	.container-custom-land {
		max-width: 1280px !important;
	}
	.sec2-box-item {
		width: 22%;
	}
	.sec2-box-item:first-of-type {
		//top: 207px !important;
		top: 245px !important;
	}
	.sec2-box-item:nth-child(2) {
		//top: 37px !important;
		top: 85px !important;
	}
	.sec2-box-item:last-of-type {
		//bottom: 116px !important;
		bottom: 90px !important;
	}
}

@media (min-width: 1215px) and (max-width: 1274px) {
	.container-custom-land {
		max-width: 1200px !important;
	}
	.sec2-box-item {
		width: 22%;
	}
	.sec2-box-item:first-of-type {
		top: 243px !important;
	}
	.sec2-box-item:nth-child(2) {
		top: 82px !important;
	}
	.sec2-box-item:last-of-type {
		bottom: 91px !important;
	}
}

@media (min-width: 1150px) and (max-width: 1215px) {
	.container-custom-land {
		max-width: 1130px !important;
	}
	.sec2-box-item {
		width: 22%;
	}
	.sec2-box-item:first-of-type {
		top: 271px !important;
	}
	.sec2-box-item:nth-child(2) {
		top: 115px !important;
	}
	.sec2-box-item:last-of-type {
		bottom: 63px !important;
	}

	.header-sec2 {
		div {
			p {
				width: 42% !important;
			}
		}
	}
	.landPage .sec1 h3 {
		max-width: 58%;
	}
}

@media (min-width: 1105px) and (max-width: 1149px) {
	.container-custom-land {
		max-width: 1100px !important;
	}
	.sec2-box-item {
		width: 22%;
	}
	.sec2-box-item:first-of-type {
		top: 241px !important;
	}
	.sec2-box-item:nth-child(2) {
		top: 97px !important;
	}
	.sec2-box-item:last-of-type {
		bottom: 84px !important;
	}

	.header-sec2 {
		div {
			p {
				width: 42% !important;
			}
		}
	}
	.landPage .sec1 h3 {
		max-width: 58%;
	}
	.sec2 .containt-sec2 .sec2-box .sec2-box-item p {
		max-width: 78%;
	}
}
@media (min-width: 1024px) and (max-width: 1104px) {
	.container-custom-land {
		max-width: 1010px !important;
	}
	.sec2-box-item {
		width: 22%;
	}
	.sec2-box-item:first-of-type {
		top: 266px !important;
	}
	.sec2-box-item:nth-child(2) {
		top: 133px !important;
	}
	.sec2-box-item:last-of-type {
		bottom: 57px !important;
	}

	.header-sec2 {
		div {
			p {
				width: 60% !important;
			}
		}
	}
	.landPage .sec1 h3 {
		max-width: 62%;
	}
	.sec2 .containt-sec2 .sec2-box .sec2-box-item p {
		max-width: 78%;
	}

	.sec2 .containt-sec2 .sec2-box .sec2-box-item .bg-box {
		[dir="rtl"] & {
			right: 30%;
			top: 10px;
		}
	}

	.sec1 h2,
	.sec1 h3,
	.home-input-containt,
	.header-sec2,
	.sec1 .home-list {
		padding: 0 28px;
	}
}

.sec3 {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	justify-content: center;

	.position-top-sec3 {
		position: absolute;
		top: 150px;
		align-items: center;
		justify-content: center;
	}

	.services-span {
		background-color: #0c3b5c;
		width: 390px;
		height: 390px;
		border-radius: 100%;
		box-shadow: 1px 7px 39px #00000029;
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		justify-content: center;
		text-align: center;
		color: #fff;
		font-size: 60px;
		font-weight: 700;
	}
	.ItemS-line {
		width: 80%;
		display: block;
		margin: auto;
		min-height: 300px !important;
		//backdrop-filter: blur(1px);
		img {
			width: 100%;
		}
	}
	.ItemS-line-containt {
		height: 350px !important;
	}
	.container-BoxLand {
		position: absolute;
		right: 5%;
		top: -10px;
		justify-content: space-around;
		margin: auto;
		width: 90%;
		display: flex;
	}
	.item-BoxLand {
		cursor: pointer;
		position: relative;
		width: 16%;
		height: 23vh;
		border-radius: 100%;
		background-color: white;
		box-shadow: 1px 7px 39px #00000029;
		overflow: hidden;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		margin-left: 10px;
		margin-right: 10px;

		img {
			width: 85px;
		}
		.item-BoxLand-name {
			color: #cb9a51;
			z-index: 9999999999999;
			font-size: 21px;
			font-weight: 700;
			position: absolute;
			top: 40%;
			left: 30%;
		}
	}
	.item-BoxLand:nth-child(3) .item-BoxLand-name {
		color: #cb9a51;
		z-index: 9999999999999;
		font-size: 21px;
		font-weight: 700;
		position: absolute;
		top: 40%;
		left: 12%;
		[dir="rtl"] & {
			left: 30%;
		}
	}
	.item-BoxLand.activeLand:after {
		content: "";
		color: #cb9a51;
		width: 14.7rem;
		height: 23vh;
		text-align: center !important;
		//border-radius: 100%;
		position: absolute;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 21px;
		font-weight: 700;
		top: 0;
		right: 0;
		background-color: #0c3b5c;
		opacity: 0.93;
		z-index: 99999999;
	}
	// .item-BoxLand:nth-child(1):hover.activeLand:after {
	// 	content: "Visas";
	// }
	// .item-BoxLand:nth-child(2):hover.activeLand:after {
	// 	content: "Hotels";
	// }
	// .item-BoxLand:nth-child(3):hover.activeLand:after {
	// 	content: "Transportation";
	// }
	// .item-BoxLand:nth-child(4):hover.activeLand:after {
	// 	content: "Flight";
	// }
	// .item-BoxLand:nth-child(5):hover.activeLand:after {
	// 	content: "Wallet";
	// }
	.item-BoxLand:hover:after {
		content: "";
		color: #cb9a51;
		width: 13.7rem;
		height: 23vh;
		//border-radius: 100%;
		position: absolute;
		display: flex !important;
		align-items: center !important;
		justify-content: center !important;
		font-size: 21px;
		font-weight: 700;
		top: 0;
		right: 0;
		background-color: #0c3b5c;
		opacity: 0.93;
		z-index: 99999999;
	}
	// .item-BoxLand:nth-child(1):hover:after {
	// 	content: "Visas";
	// }
	.item-BoxLand:nth-child(1),
	.item-BoxLand:nth-child(5) {
		margin-top: 45px;
	}
	.item-BoxLand:nth-child(2),
	.item-BoxLand:nth-child(4) {
		margin-top: 150px;
	}
	.item-BoxLand:nth-child(3) {
		margin-top: 225px;
	}
	.box-cradLand {
		background-color: #fff;
		border: 1px solid #ebebeb;
		padding: 30px;
		border-radius: 20px;
		margin: 10px 0;
		height: 170px;
		img {
			width: auto !important;
		}
		p:nth-child(1) {
			color: #cb9a51;
			font-size: 35px;
		}
		p:nth-child(2) {
			color: #2d2d2d;
			font-size: 20px;
			margin-top: 20px;
		}
	}
	.pt-280 {
		padding: 150px 0;
	}
}

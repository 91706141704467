.statistics {
	.main-tabs .main-nav .nav-item {
		margin-inline-start: 0;
	}
}
.revenue-statistics {
	h1,
	h2,
	h3,
	h3,
	h4,
	h5,
	h6 {
		color: #324356;
	}
	p {
		color: #686869;
		font-size: 12px;
	}

	.cards {
		.desc {
			h6 {
				position: relative;
				&:after {
					content: "";
					position: absolute;
					width: 30px;
					height: 3px;
					bottom: -10px;
					left: 0;
					[dir="rtl"] & {
						left: auto;
						right: 0;
					}
				}
			}
			.count {
				font-size: $text-title4;
				font-weight: 700;
				color: $dark-blue;
				padding-top: 0.3rem;
			}
			span {
				color: #acacac;
				font-size: $text-caption;
				font-weight: normal;
			}
		}
		.booking-text {
			color: #686869;
		}
		.hotels {
			border-bottom: 3px solid #0087f6;
			border-left: 1px solid #0087f6;
			border-right: 1px solid #0087f6;
			.desc {
				h6 {
					color: #0087f6;
					font-weight: 700;
					position: relative;
					&:after {
						background: #0087f6;
					}
				}
			}
		}

		.transportation {
			border-bottom: 3px solid #f68d33;
			border-left: 1px solid #f68d33;
			border-right: 1px solid #f68d33;
			.desc {
				> h6 {
					color: #f68d33;
					font-weight: 700;
					&:after {
						background: #f68d33;
					}
				}
			}
		}
		.total {
			border-bottom: 3px solid #00b34c;
			border-left: 1px solid #00b34c;
			border-right: 1px solid #00b34c;
			.desc {
				h6 {
					color: #00b34c;
					font-weight: 700;
					&:after {
						background: #00b34c;
					}
				}
			}
		}
	}
}

.activity {
	min-height: auto;
	font-size: 12px;

	.title {
		padding: 0.5rem 0;
		color: $blueBlack;
		.text {
			font-size: 14px;
			position: relative;
			&:after {
				content: "";
				position: absolute;
				width: 40px;
				height: 2px;
				bottom: -7px;
				left: 0;
				background: #0087f6;
				[dir="rtl"] & {
					left: auto;
					right: 0;
				}
			}
			[dir="rtl"] & {
				padding-top: 0.1rem;
			}
		}
	}
	.date {
		text-align: center;
		position: relative;
		p {
			background: #f4f4f4;
			display: inline-block;
			padding: 0.3rem 1rem;
			border-radius: 5px;
			color: #acacac;
			margin: 1rem 0;
		}
		// &::after {
		// 	content: "";
		// 	position: absolute;
		// 	top: -34px;
		// 	left: 44px;
		// 	height: 172%;
		// 	border: 1px dashed #e9e9ef !important;
		// 	z-index: 0;
		// 	[dir="rtl"] & {
		// 		left: auto;
		// 		right: 42.5px;
		// 	}
		// }
	}

	.date {
		&:not(:first-child) {
			background-color: red;
		}
	}

	.main {
		position: relative;
		.time {
			color: #4d4f5c;
		}
	}
	.tes {
		// position:relative;
		&::after {
			content: "";
			position: absolute;
			top: 23px;
			left: 44px;
			height: 100%;
			border: 1px dashed #e9e9ef !important;
			z-index: 0;
			[dir="rtl"] & {
				left: auto;
				right: 42.5px;
			}
		}
	}

	.main:last-child .tes::after {
		border: none !important;
	}
}

.filter-month {
	width: 154px;
	padding: 0 5px;
	.no-gutters {
		display: flex;
		align-content: center;
	}
	.my-2 {
		margin: 0 !important;
	}
	.control-field__body {
		padding: 0.5rem;
	}
}

.wallet-icon {
	.wallet-contain {
		background: #f4f4f4 !important;
		border-bottom: 8px solid #f4f4f4;
	}
	p {
		i {
			font-size: 19px !important;
			margin: 0 2px;
		}
	}
}
.statistics-container {
	.product-builder-list {
		padding: 0;
		border: 1px solid #dee2e6;
		border-bottom: 0px;
		h6 {
			font-size: 16px;
			color: #2d2d2d;
		}
		P {
			font-size: 14px;
		}
		.product-buttons {
			.btn {
				font-size: 15px;
				font-weight: 700;
			}
		}
	}
	.revenue-statistics {
		.title {
			p {
				font-size: 15px;
			}
		}
	}
	.main-tabs {
		background-color: transparent !important;
	}
	.main-nav-statistics {
		background-color: #ebebeb;
		.nav-link.active {
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			min-height: 60px !important;
			background-color: #fff;
			border-radius: 0 !important;
			border: 1px solid #dfdfdf;
			border-bottom: 0;
			color: #cb9a51;
			font-weight: 700;
		}
		.nav-link {
			/* border: none; */
			color: #324356;
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			min-height: 58px;
			min-width: auto !important;
			border: 0 !important;
			font-weight: 600;
		}
	}
}

@media (min-width: 800px) and (max-width: 1180px) {
	.revenue-statistics {
		.img-box {
			display: none !important;
		}
	}
}

.visa-header {
	background-color: #fff;
	padding: 12px 0;
	border: 1px solid #d9d9da;
	border-bottom: 0px;

	.btn-contract {
		background-color: #cb9a51;
		color: #fff;
	}
}
.table-update {
	tbody tr:nth-of-type(odd) {
		background-color: rgba(0, 0, 0, 0.01);
	}
	thead th {
		border-bottom: 0 !important;
		color: #0c3b5c;
		font-size: 15px;
		font-weight: 700;
	}
	tbody tr:hover {
		background-color: #faf2e8 !important;
	}
	tbody td {
		color: #464646;
		font-size: 13px;
		font-weight: 600;
	}
	.bg-status {
		background: #28a745;
		border-radius: 5px;
		color: #fff;
	}
	.actions {
		i {
			color: #464646;
		}
	}
}
.filter-update {
	.main-filter {
		background-color: #f4f4f4 !important;
		border: 1px solid #d9d9da;
	}
	.controls-field {
		.control-field {
			background-color: #ffffff;
			border: 1px solid #ebebeb;
			border-radius: 0.3125rem;
			font-size: 12px;
			height: 35px;
			border: 1px solid #d9d9da;
			margin: 0 !important;
		}
		.control-field__label {
			font-size: 13px;
			font-weight: 600;
			color: #2d2d2d;
			margin-bottom: 0;
			width: 100%;
		}
		.control-field__input {
			border: 0;
			font-size: 14px;
			width: 90%;
			color: #2d2d2d;
		}
		.control-field__body {
			padding: 6px 8px;
			flex: 1 1 auto;
			border-radius: 5px;
		}
	}
	.filter-btn {
		margin-top: 20px;
		.apply {
			button {
				background-color: #0c3b5c !important;
				color: #fff;
				i {
					color: #fff;
					font-size: 12px;
					margin: 0 3px;
				}
			}
		}
		.clear {
			button {
				border: 1px solid #c5c5c5 !important;
			}
		}
	}
}

.agents {
	margin: 0 !important;
	padding: 0 !important;
	.agent-item {
		.agent-container {
			background-color: #dfdfdf;
			display: table;
			border-radius: 5px;
			width: 100% !important;

			.agent-name {
				width: 15%;
				height: 100%;
				border-radius: 5px 0 0 5px;
				display: table-cell;
				text-align: center;
				vertical-align: middle;
				h3 {
					font-weight: 700;
				}
			}
			.agent-content {
				width: 85%;
				padding: 10px 15px;
				border-radius: 0 5px 5px 0px;
				display: table-cell;

				.custom-color {
					color: #2d2d2d;
				}
				.custom-border-color {
					border: 1px solid #dfdfdf;
				}
			}
		}
	}
	.contract-name-containt {
		.custom-color {
			color: #2d2d2d;
		}
		.custom-border-color {
			border: 1px solid #dfdfdf;
		}
	}
}
.contract-length {
	background-color: #fff !important;
	padding: 15px 20px;
	border: 1px solid #d9d9da;
	border-bottom: 0;
	h4 {
		font-weight: 700;
		color: #2d2d2d;
	}
}

.view-group {
	.select-item,
	.select-all {
		filter: invert(1%) hue-rotate(192deg) brightness(1.2);
	}
	.btn:hover {
		color: #fff;
		text-decoration: none;
	}
	.table thead th {
		padding: 5px;
		vertical-align: middle;
	}
	.group-card {
		.agent-container .agent-content {
			width: 100% !important;
			padding: 0 !important;
			h4 {
				font-size: 13px;
				font-weight: 700;
			}
			p {
				font-size: 14px;
			}
		}
	}
	.edit-group {
		background-color: #cb9a51;
		padding: 0px 10px;
		color: #fff;
		height: 25px;
		vertical-align: middle;
		border-radius: 3px;
		margin: 5px 7px;
	}
	.view-group-select {
		.control-field.no-gutters {
			background-color: #324356 !important;
		}
		.control-field__label {
			color: #eaeff7 !important;
		}
		.no-gutters
			.control-field__select
			div[class$="-control"]
			div[class$="-ValueContainer"]
			.css-1wa3eu0-placeholder {
			color: #fff !important;
		}
		.no-gutters
			.control-field__select
			div[class$="-control"]
			div[class$="-indicatorContainer"] {
			color: #fff;
		}
	}
	.table-header {
		border: 1px solid #d9d9da;
		border-bottom: 0px;
		background-color: #f4f4f4;
		padding: 10px;
		label {
			display: inline;
			margin-bottom: 0.5rem;
		}

		.custom-color {
			color: #2d2d2d;
		}
		.custom-border-color {
			border: 1px solid #dfdfdf;
		}
		.btn:hover {
			color: #2d2d2d;
		}
	}
	.btn-white {
		background-color: #fff !important;
	}

	.header {
		border: 1px solid #d9d9da;
		border-bottom: 0px;
		padding: 10px 15px;
		background-color: #fff;
		h3 {
			font-weight: 600;
		}
		button {
			padding: 7px 20px;
			font-weight: 600;
			font-size: 14px;
		}
	}
}
.print-header {
	.btn:hover {
		color: #fff;
		text-decoration: none;
	}
	border: 1px solid #dfdfdf;
	border-bottom: 0px;
}
.visa-print-content {
	border: 1px solid #dfdfdf;
	.box2 {
		.row {
			display: flex;
			flex-wrap: wrap;
			/* margin-right: -15px; */
			/* margin-left: -15px; */
			justify-content: center;
		}
	}
}
.btns {
	.btn:hover {
		color: #fff;
	}
}
.btn-yellow {
	background-color: #cb9a51;
	color: #fff;
}
.btn-blue-dark {
	background-color: #0c3b5c;
	color: #fff;
}
.font-16 {
	font-size: 16px;
}
.w-40 {
	width: 40%;
}
.text-gray {
	color: #686d71;
}
.bg-gray {
	background-color: #686d71;
	font-weight: 500;
	font-size: 18px;
}
.border-radius-sm {
	border-radius: 5px;
	border: 1px solid rgb(219, 219, 219);
}
.container-custom {
	max-width: 1470px !important;
	margin: auto !important;
}
.traveler-tab {
	.main-tabs .main-nav .nav-item {
		min-height: 70px;
		display: flex;
		align-items: center;
		justify-content: center;
		background: #ffffff;
		width: 80px !important;
		margin-inline-start: 0.5rem;
	}
	.main-tabs .main-nav .nav-link.active {
		width: 80px;
		color: #ffffff !important;
		min-height: 70px;
	}
}
.bg-filter {
	background-color: #fff !important;
	width: 100% !important;
}
.contract-name {
	padding: 0 !important;
	margin: 0 !important;
	.contract-name-containt {
		margin: 0 -7px;
	}
}
// .view-group {
// 	.table-title {
// 		background-color: #fff;
// 		display: inline-block;
// 		padding: 10px 25px;
// 		border: 1px solid #e6e6e6;
// 		border-bottom: 2px solid #fff !important;
// 	}
// 	.table-layout {
// 		border: 1px solid #e6e6e6;
// 		border-top: none;
// 		border-radius: 0px 0px 5px 5px !important;
// 	}
// 	.view-group-input {
// 		.control-field__body {
// 			padding: 0px 10px 15px 10px;
// 			flex: 1 1 auto;
// 			border-radius: 0.3125rem;
// 		}
// 		.view-group-select {
// 			.control-field__body {
// 				background-color: #324356 !important;
// 			}
// 			.control-field__label {
// 				color: #eaeff7 !important;
// 			}
// 			.no-gutters
// 				.control-field__select
// 				div[class$="-control"]
// 				div[class$="-ValueContainer"]
// 				.css-1wa3eu0-placeholder {
// 				color: #fff !important;
// 			}
// 			.no-gutters
// 				.control-field__select
// 				div[class$="-control"]
// 				div[class$="-indicatorContainer"] {
// 				color: #fff;
// 			}
// 		}
// 	}
// }
.group-card,
.member-info {
	.row {
		div[class^="col-"] {
			.css-107lb6w-singleValue {
				color: #324356 !important;
			}
		}
	}
	label {
		display: inline;
	}
}
.member-photo {
	button {
		color: #cb9a51;
	}
}
.member-info {
	.mrz {
		border-radius: 5px;
		.mrz-label {
			border-radius: 5px 0 0 5px;
			p {
				color: #fff;
			}
		}
	}
	.no-gutters .control-field__select div[class$="-control"],
	.control-field__body,
	input[type="text"]:disabled,
	.date-picker .control-field__body {
		background-color: #fff !important;
	}
	.date-picker .control-field__body {
		border-bottom: 1px solid #e8e8e8 !important;
		.date-icon {
			z-index: 100;
		}
	}
	.custom-border-color {
		border: 1px solid #e8e8e8 !important;
	}
	.custom-color {
		color: #2d2d2d;
	}
}
.header-view {
	.btn:hover {
		color: #fff;
	}
	span {
		display: inline-block;
		border: 1px solid rgb(197, 197, 197);
		padding: 3px 10px;
		border-radius: 5px;
		margin: 0 8px;
		color: #0c3b5c;
	}
}
.print-visa {
	background-color: #fff;
	header {
		background-image: url(../../../assets/images/printvisa/header.png);
		background-position: center center;
		background-size: cover !important;
		background-repeat: no-repeat;
		width: 100% !important;
		height: 160px !important;
	}

	footer {
		background-image: url(../../../assets/images/printvisa/footer.png);
		background-position: center center;
		background-size: cover !important;
		background-repeat: no-repeat;
		width: 100% !important;
		height: 198px !important;
	}
	// img {
	// 	height: 185px !important;
	// 	width: 100%;
	// }
	.barcode {
		font-family: "Libre Barcode 128", cursive;
		font-size: 64px;
		height: 70px;
	}
	.bg-gray-print-barcode {
		p {
			background-color: #d2d2d2 !important;
			font-size: 21px;
			font-weight: 600;
			font-family: "cairo";
			padding: 15px;
			border-radius: 7px;
			margin-top: 8px;
		}
	}
	.img-print-visa {
		width: 240px !important;
		display: flex;
		justify-content: center;
		//new ya taha
		width: 75%;
		display: flex;
		justify-content: center;
		// background: #09c;
		margin: auto;
		img {
			border: 1px solid #ddd;
		}
	}
}
.date-picker .control-field__body {
	background-color: #e8e8e8 !important;
}

.DateInput__disabled {
	background-color: #e8e8e8 !important;
}
.activePage {
	background-color: #0c3b5c !important;
	color: #fff !important;
}
.pagination span {
	color: #0c3b5c !important;
	background-color: transparent;
}
.page-item {
	margin: 0 !important;
}
.pagination span:hover,
.pagination .page-link:hover {
	background: #0c3b5c;
	color: #ffffff;
}

.view-group .table-layout .css-1uccc91-singleValue {
	color: #fff !important;
	font-weight: 600;
	font-size: 14px !important;
}
.change-photo-btn {
	font-size: 16px;
}
.text-underline {
	text-decoration: underline !important;
}
.text-gray-1 {
	color: #acacac;
}
.bg-gray-print {
	background-color: #d2d2d2 !important;
	font-size: 21px;
	font-weight: 600;
	font-family: "cairo";
}
.brd {
	border: 2px solid #327171;
}

.bg-dark-white {
	background-color: #deebf3 !important;
}
.brn-blue-dark {
	background-color: #0c3b5c;
}
.bg-dark-blue {
	background: #324356 !important;
}
.font-dark-blue {
	color: #0c3b5c !important;
}

.box1 {
	.row-1 {
		display: flex;
		width: 100% !important;
		//New Ya Taha
		height: 270px;
		margin-top: 20px;
		.col1-md-3 {
			//	width: 18% !important;
			//New Ya Taha box image
			width: 25%;

			//margin: auto 0px;
		}
		.col1-md-9 {
			width: 80% !important;
			display: flex;
			flex-wrap: wrap;
			//new ya taha
			width: 75% !important;
			justify-content: center !important;
			.row1 {
				width: 100% !important;
				display: flex;
				flex-wrap: wrap;
				justify-content: start;

				.col1-md-3 {
					width: 21.8% !important;
					margin: 5px 0px;
					border-radius: 7px;
				}
				.col1-md-5 {
					//width: 51.2% !important;
					//margin: 5px 13px;
					//new ya taha
					width: 50.2% !important;
					border-radius: 7px;
					margin: 5px 20px;
					width: 48.5% !important;
					//image width="230" height="306"    mt-3 >div
				}
			}
		}
	}
}
.border-radius-10 {
	border: 10px;
}
.box2,
.box3 {
	.row {
		display: flex;
		flex-wrap: wrap;
		margin-right: -15px;
		/* margin-left: -15px; */
		justify-content: center;
		.col-md-2,
		.col-md-7 {
			border-radius: 7px;
		}
	}
}
// .box3 {
// 	.row1 {
// 		width: 100% !important;
// 		display: flex;
// 		flex-wrap: wrap;
// 		justify-content: center;
// 	}
// }

.visa-login {
	height: 78vh !important;
	//margin-top: 80px !important;
}

.mofa-modal {
	.control-field {
		height: 39px !important;
	}
	.custom-select-text {
		color: #2d2d2d !important;
	}
}
@media print {
	body,
	html {
		background-color: #fff !important;
	}
	.print-visa {
		margin: 0 !important;
		header {
			//width: 100% !important;
			border: 0px !important;
			height: 200px !important;
		}
		footer {
			height: 200px !important;
		}
	}
	.visa-print-content {
		* {
			font-size: 18px; //14px
		}
	}
	.print-header {
		display: none !important;
		visibility: hidden !important;
	}
	.box1 {
		.row-1 {
			.col1-md-3 {
				//width: 18% !important;
				margin-right: 4px !important;
				.img {
					width: 220px !important;
					img {
						width: 100%;
					}
				}
			}
			.col1-md-9 {
				.col1-md-5 {
					margin: 5px 15px !important;
				}
				.col1-md-3 {
					//width: 18% !important;
					// margin: 5px 0px;
				}
			}
		}
	}
	.applicationNo {
		p {
			font-size: 16px !important;
			font-weight: 700;
		}
	}
}

@media (min-width: 800px) and (max-width: 1180px) {
	.filter-btn.col-md-2 {
		margin: 0 15px;
		button {
			width: 80px;
			margin: 0 5px;
		}
	}
	.view-group {
		.clear {
			button {
				padding: 9px 7px;
			}
		}
	}
	.mrz {
		font-size: 13px;
	}
	.photo-label {
		font-size: 14px;
	}
	.text-16 {
		font-size: 16px;
	}
}

.d-hidden {
	display: none !important;
}
.bg-dark-yellow {
	background-color: #daa249;
}
.opacity-5 {
	opacity: 0.5 !important;
}

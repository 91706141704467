@media (min-width: 321px) and (max-width: 576px) {
	.login-page,
	.register-page,
	.reset-password {
		padding: 0 1.5rem;
	}
	.login-page-data,
	.register-page,
	.reset-password {
		img.safa_logo {
			display: block !important;
			margin: 2rem auto;
		}
	}

	.reset-password-bg {
		display: none;
	}

	.notification {
		.notifaction-items-header {
			right: auto !important;
			left: 0 !important;
			position: fixed !important;
			top: 7px !important;
		}
	}
	html[dir="ltr"] .notification .notifaction-items-header {
		right: -100px !important;
		left: auto !important;
	}
	.container-custom-md-modal,
	.container-custom-lg-modal {
		max-width: 320px !important;
	}
	.modal-dialog {
		padding: 30px;
		width: auto !important;
		min-width: 390px !important;
	}
		.inventory-header-totals {
			flex-wrap: wrap;
			.totals_container {
				flex-wrap: wrap;
				justify-content: flex-start !important;
				span {
					margin: 0.25rem !important;
				}
			}
		}

	.inventory{
		.apply, .clear{
			button{
				padding: 5px 10px !important;

			}
		}
	}
	.market-search-guide-btn{
		display: none !important;
	}
}

@media (min-width: 320px) and (max-width: 576px) {
	td, th {
    white-space: nowrap;
}
.pagination-container {
	flex-direction: column;
}
	.marketplace-details {
		.mybooking-search-wrapper.search-bar {
			padding: 0;
			.container {
				padding: 0;
			}
		}
		.select-flight .sorting {
			flex-direction: column-reverse;
			align-items: baseline;
		}
		.sort-filter {
			display: flex;
			margin-bottom: 5px;
			span {
				flex-shrink: 0;
			}
		}
		.hotel-detail-section {
			padding: 0px;
			border-radius: 6px;
			overflow: hidden;
			.img-box {
				overflow: hidden;
				width: 100% !important;
			}
			.img-box img {
				object-fit: fill;
			}
			.hotel-header {
				display: flex;
				align-items: flex-start;
				margin-bottom: 20px;
				flex-direction: column;
			}
		}
		.new-price-number {
			width: auto;
			border-bottom-left-radius: 6px;
			border-bottom-right-radius: 6px;
		}
		
		.titleHotelDescription {
			display: none;
		}
		.available-section {
			.details-item {
				overflow-x: auto;
			}
		}
		.hotel-amenities-new ul li, .hotel-amenities-new ul li {
			width: 50%;
		}
	}
	.boxCancellation{
		margin-bottom: 8px;
	}
	.responsive-padding-container{
		padding-left: 30px;
		padding-right: 30px;
		.cost-title-container{
			justify-content: center !important;
		}
	}
	.payModal {
		.radios{
			flex-direction: column-reverse;
			.box-item{
				width: auto;
			}
			.box-item:first-child{
				margin-top: 8px;
			}
		}
	}
}

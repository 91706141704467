// Colors
$black: #000000;
$white: #ffffff;
$gray-100: #f4f4f4;
$gray-150: #e8e8e8;
$gray-200: #eae8e9;
$gray-250: #c8c7c7;
$gray-300: #acacac;
$gray-400: #dee1e3;
$gray-900: #2d2d2d;
$dark-gray: #686d71;
$dark-blue: #0c3b5c;
$blue: #0184fe;
$red: #ff5f59;
$orange: #f28f08;
$green-100: #00b43740;
$green: #00b437;
$goldColor: gold;
$orangColor: #dc7542;
$blueBlack: #354f5c;
$whiteBlue: #edf2f7;
$orange-500: #ba5f20;
//$main-bg-sbs: linear-gradient(112deg, #dea237 0%, #dea237 20%, #ba5f20 100%);
$main-bg-sbs: linear-gradient(180deg, #daa249 0%, #cc9a50 100%);

// Font Sizes
$text-title: 24px;
$text-title2: 22px;
$text-title3: 20px;
$text-title4: 25px;
$text-subtitle: 18px;
$text-headline: 16px;
$text-body: 14px;
$text-caption: 12px;
$text-captio2: 10px;
$text-captio3: 11px;

// IcoMoon
$icomoon-font-family: "SBS" !default;
$icomoon-font-path: "../../assets/fonts/icomoon" !default;

$icx-arrow-right: "\e908";
$icx-bell: "\e909";
$icx-financail-payment: "\e90a";
$icx-comment: "\e90b";
$icx-camera-location: "\e90c";
$icx-edit: "\e90d";
$icx-hotel: "\e90e";
$icx-map: "\e90f";
$icx-itinerary: "\e910";
$icx-moon: "\e911";
$icx-plane: "\e912";
$icx-share: "\e913";
$icx-star: "\e914";
$icx-transport: "\e915";
$icx-trash: "\e916";
$icx-eye-slash: "\e907";
$icx-building: "\e900";
$icx-eye: "\e901";
$icx-images: "\e902";
$icx-shield: "\e905";
$icx-user: "\e906";
$font14: 14px;
$closedNav: 60px;
$opendNav: 250px;
$opendNavMax: 25%;
$orange-gradient: linear-gradient(
	112deg,
	rgba(222, 162, 55, 1) 0%,
	rgba(222, 162, 55, 1) 20%,
	rgba(186, 95, 32, 1) 100%
);

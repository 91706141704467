@media (min-width: 321px) and (max-width: 576px) {
	.navbar {
		// .lang-dropdown {
		// 	display: none;
		// }
		.custom-notification.wallet-icon{
			display: none;
		}
		.dropdowns .dropdown button {
			padding-left: 5px !important;
			padding-right: 5px !important;
	}
		z-index: 1002 !important;
		.side-nav-button {
			margin-inline-start: 0 !important;
			margin-inline-end: 8px !important;
			position: initial !important;
			i {
				font-size: 24px !important;
			}
		}
	}

	.mybooking-grid-container {
		margin-top: 26px;
	}

	.TechnicalSupport-width {
		img {
			width: 28.87px !important;
			display: none;
		}
		.small-support {
			display: block !important;
		}
	}

	.upload-images {
		height: 100px;
		display: flex;
		// justify-content: center;
		align-items: center;

		& > div {
			flex-direction: column;
			align-items: center;
		}
		small {
			align-items: center;
		}
		img {
			display: none;
		}
		.visable {
			display: block !important;
		}
		p {
			margin-top: 0 !important;
		}
		.upload-message {
			font-size: 16px;
		}
		button {
			position: static !important;
			font-size: 16px;
			padding-top: 11px;
			padding-bottom: 11px;
		}
	}

	.modal-cancel-reservation .modal-footer button {
		width: 100% !important;
	}

	#sideNav.closed {
		width: 0 !important;
		inset-inline-start: -50% !important;
	}
	#sideNav.opend {
		max-width: 40% !important;
		// display: flex;
	}
	#sideNav::after {
		content: " ";
		width: 60%;
		height: 100vh;
		background-color: #ffffff99;
		position: fixed;
		inset-inline-end: 0;
		visibility: hidden;
		transition: all 0.7s;
		opacity: 0;
	}
	#sideNav.opend::after {
		visibility: visible;
		opacity: 1;
	}

	.container_layout {
		width: 100% !important;
	}

	// search market place
	.market-search-conatainer {
		.market-search .main-tabs {
			.heading,
			.tab-content {
				padding-left: 2rem;
				padding-right: 2rem;
			}
		}
		.tab-content {
			height: 100vh;
			transition: 0.7s;
		}
		.top-screen {
			position: relative !important;
			top: 0 !important;
		}
	}
	.market-search-tabs-container {
		display: flex;
		width: 100%;
		justify-content: center;
		padding-left: 2rem;
		padding-right: 2rem;
		height: 100vh;
		& ul {
			display: flex;
			flex-direction: row !important;
			align-content: flex-start;
			justify-content: flex-start;
			i.fas {
				display: none !important;
			}
			& li.nav-item {
				width: 50%;
				padding: 8px;
				a {
					margin: 0 !important;
					width: 100% !important;
				}
			}
		}
	}
	.search-banner {
		display: none !important;
	}

	.market-search-conatainer,
	.wallet-wrraper-responsive .container {
		padding-left: 0 !important;
		padding-right: 0 !important;
		.main-tabs1 {
			flex-direction: column !important;
		}
		.main-tabs1 .main-nav1 {
			flex-direction: row !important;
		}
		.market-search .nav-tabs .nav-item {
			width: 50% !important;
		}
	}
	.wallet-wrraper-responsive {
		.main-nav1,
		.interest {
			min-height: 100vh !important;
		}
		.main-tabs1 .main-nav1 li {
			width: 100%;
			padding: 20px;
			.box-wallet-tab {
				width: 100%;
			}
			img {
				width: 20% !important;
			}
			small {
				display: none;
			}
		}
		.product-build__leftside-menu.main-nav-sbs {
			ul.nav-tabs {
				flex-direction: column;
				height: auto !important;
			}
			ul.nav-tabs > div {
				width: 100% !important;
			}
			ul.nav-tabs li {
				width: 50% !important;
			}
			.wallet-pay-buttons > button {
				margin-top: 8px;
				margin-bottom: 8px;
			}
			.wallet-pay-buttons .recharge-buttons {
				flex-direction: column;
				align-items: center;
				margin-top: 16px;
				margin-bottom: 16px;
				& button {
					width: 55% !important;
				}
			}
		}
		.wallet-wrraper0 {
			h6 {
				margin: 0.5rem !important;
			}
		}
		.main-filter-payment {
			flex-wrap: wrap;
		}
		.main-filter-payment > div {
			width: 50%;
			padding-left: 8px;
			padding-right: 8px;
			margin-right: 0 !important;
			margin-left: 0 !important;
		}
		.product-build__tabs-content {
			padding: 18px;
			.responsive-wallet-tap {
				border-radius: 10px;
			}
		}
	}
	.recharge-Modal {
		// width: 480px !important;
		.wallet-image {
			display: none;
		}
		.recharge-radio {
			flex-direction: column;
		}
		.recharge-radio > div {
			width: 100%;
			margin: 8px 0 !important;
		}
	}
	.table-container {
		overflow-x: auto !important;
		min-height: 190px;
	}
	.navbar {
		margin-bottom: 0 !important;
	}

	.main-filter-payment {
		flex-wrap: wrap;
	}
	.container_layout .inventory {
		.main-nav-sbs {
			background-color: #f7f8fa !important;
		}
		padding: 0;
		.tab-content {
			margin: 24px;
			border-radius: 8px !important;
			overflow: hidden;
		}
		.tab-icons {
			ul li {
				width: 25%;
				border-inline-end: 1px solid #fff;
				span {
					display: none;
				}
				img {
					width: 34px;
				}
			}
		}
	}
	.active-log-details,
	.inventory-booking-details {
		padding: 24px !important;
		.main-nav-sbs {
			border-top-left-radius: 8px;
			border-top-right-radius: 8px;
			overflow: hidden;
		}
		.tab-content {
			border-bottom-left-radius: 8px;
			border-bottom-right-radius: 8px;
			overflow: hidden;
		}
		.nav .nav-item {
			width: fit-content;
		}
	}
	.active-log-details {
		.main-filter-payment > div {
			margin-right: 0 !important;
			margin-left: 0 !important;
		}
	}
	.inventory-booking-details {
		.res-remove-padding {
			padding: 0 !important;
			.btns-booking {
				margin-left: 8px;
				margin-right: 8px;
			}
			.hotel-info {
				padding-right: 0 !important;
				padding-left: 0 !important;
			}
			button.text-uppercase {
				padding: 8px !important;
				font-size: 14px !important;
			}
			button.btn-link {
				font-size: 14px !important;
			}
		}
		.hotel-name {
			flex-direction: column;
			.hotel-star-rate {
				margin: 0 !important;
				padding: 8px 0 !important;
			}
			& > .d-flex {
				flex-direction: column;
			}
		}
		.hotel-details .progressbar-info .step-holder:after {
			content: "- - - - - - - - - -";
		}
		.hotel-details .progressbar-info .remove-after:after {
			content: "";
		}
		.hotel-details .progressbar-info {
			.item-names {
				white-space: pre-wrap;
				width: 90px;
				margin: 0 !important;
			}
			.progressbarItem:last-of-type .item-names,.progressbarItem:first-of-type .item-names {
				margin: 0 -25px !important;
				text-align: center;
			}
		}
		.btns-booking {
			justify-content: space-between !important;
		}
		.booking-data.my-2 {
			margin: 0 !important;
		}
	}
	.booking-details-pay-modal {
		.modal-pay-box {
			flex-wrap: wrap;
			gap: 12px;
		}
		.payment-box {
			margin: 0 !important;
			flex: 40%;
		}
		.must-box {
			flex-grow: 1;
		}
		.box-radio-contener {
			flex-wrap: wrap;
			gap: 10px;
			.box-radio {
				margin: 0 !important;
			}
		}
	}
	.model-remark {
		min-width: 520px !important;
		max-width: 576px !important;
	}
	.SpecialRequests .form-check {
		min-width: 100% !important;
	}
}

@media (max-width: 320px) {
	.custom-notification.wallet-icon,
	.notification,
	.navbar-brand-icon {
		display: none;
	}
	.market-search-conatainer {
		padding-top: 0 !important;
	}
	#sideNav.closed {
		width: 18px !important;
		inset-inline-start: -50%;
	}
	#sideNav.opend {
		max-width: 70% !important;
	}
	.navbar {
		z-index: 1002 !important;
		.side-nav-button {
			margin-inline-start: 0 !important;
			margin-inline-end: 8px !important;
			position: initial !important;
			i {
				font-size: 24px !important;
			}
		}
	}
	.sidenav_link {
		height: 35px !important;
		margin: 0.25rem 0 !important;
	}
	.sidenav_header {
		margin-top: 3.5rem !important;
	}
	.market-search-conatainer,
	.wallet-wrraper-responsive .container {
		padding-left: 0 !important;
		padding-right: 0 !important;
		.main-tabs1 {
			flex-direction: column !important;
		}
		.main-tabs1 .main-nav1 {
			flex-direction: row !important;
		}
		.market-search .nav-tabs .nav-item {
			width: 100% !important;
		}
	}

	// search market place
	.market-search-conatainer {
		.market-search .main-tabs {
			.heading,
			.tab-content {
				padding-left: 0.7rem;
				padding-right: 0.7rem;
			}
		}
		.tab-content {
			transition: 0.7s;
		}
		.top-screen {
			position: relative !important;
			top: 0 !important;
		}
	}
	.nav-tabs {
		display: flex;
		flex-direction: column;
	}

	.market-search-tabs-container {
		display: flex;
		width: 100%;
		justify-content: center;
		padding-left: 1rem;
		padding-right: 1rem;
		height: 100vh;
		& ul {
			display: flex;
			flex-direction: row !important;
			align-content: flex-start;
			justify-content: flex-start;
			i.fas {
				display: none !important;
			}
			& li.nav-item {
				width: 50%;
				padding: 1px;
				a {
					margin: 0 !important;
					width: 100% !important;
				}
			}
		}
	}
	.search-banner {
		display: none !important;
	}
	.container_layout {
		width: calc(100% - 32px) !important;
	}
	.k-market-search-container .box-Umrah-Plus {
		height: auto !important;
		width: 100%;
		background-color: #ebebeb;
		border-radius: 8px;
		display: flex;
		justify-content: space-between;
		padding: 15px;
		display: flex;
		flex-direction: column;
	}

	.navbar .d-flex {
		align-items: center;
	}
	.left-slider-arrow,
	.right-slider-arrow {
		top: 47% !important;
		width: 35px !important;
		height: 35px !important;
	}
	.left-slider-arrow {
		left: 2vw !important;
	}
	.right-slider-arrow {
		right: 2vw !important;
	}
	.hotel-detail-section .img-box {
		width: 200px !important;
	}
	.new-price-number {
		width: 200px !important;
		h6 {
			font-size: 14px !important;
		}
		p {
			font-size: 13px !important;
		}
	}
	//datepicker
	.mybooking-input-wrapper.marketplace-date {
		.CalendarMonth_caption {
			font-size: 16px !important ;
		}
		.CalendarDay {
			font-size: 13px !important;
		}
		.SingleDatePicker_picker {
			top: 52px !important;

			left: unset !important;
			zoom: 0.8;
		}
	}
	.HotelSearch {
		.col-6.col-responsive {
			flex: 0 0 100% !important;
			max-width: 100% !important;
		}
		.marketplace-details .sort-filter {
			display: flex;
			margin-bottom: 5px;
			flex-direction: column !important;
		}
	}
	.marketplace-details {
		.pagination-container {
			margin: 0 !important;
			.text-body {
				display: none !important;
			}
			.pagination {
				font-size: 8px !important;
			}
		}
		.select-flight .sorting {
			flex-wrap: wrap !important;
		}
		.sort-filter {
			display: flex !important;
			flex-direction: column !important;
		}
		.mybooking-search-wrapper {
			padding: 20px 4px !important;
		}
	}
	.hotel-detail-section {
		.hotel-detail-item {
			.hotel-header {
				h6 {
					font-size: 14px !important;
				}
				.hotel-title2 span {
					font-size: 13px !important;
				}
			}
		}
	}
	.hotel-amenities-new {
		display: flex !important;
		flex-wrap: wrap !important;
		ul li {
			width: auto !important;
		}
	}
}

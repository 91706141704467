.invoice {
	.container-wrapper {
		border: 1px solid #0c3b5c;
	}

	.invoice-header {
		gap: 85px;

		.header-img {
			border: 1px solid #ebebeb;
			width: 310px;
			display: flex;
			align-items: center;
			justify-content: center;
		}
		.header-name {
			h4 {
				color: #0c3b5c;
				font-size: 18px;
				font-weight: 700;
				margin: 7px 0;
			}
		}
		.header-data {
			width: 60%;
			div {
				margin: 3px 0;
			}
			h5 {
				color: #2d2d2d;
				font-size: 20px;
			}
			p {
				color: #2d2d2d;
				font-weight: 800;
				font-size: 17px;
			}
		}
	}
	hr {
		border-top: 1px solid #0c3b5c !important;
	}
	.services {
		// margin: 8px 0;
		h3 {
			color: #0c3b5c;
			font-size: 18px;
			font-weight: 700;
		}
		.service-data {
			div {
				margin: 3px 0;
			}
			h5 {
				color: #2d2d2d;
				font-size: 20px;
			}
			p {
				color: #2d2d2d;
				font-weight: 800;
				font-size: 17px;
			}
		}
	}
}

.border-header {
	border-bottom: 1px solid #2d2d2d;
}
.border-section {
	border-bottom: 1px solid #0c3b5c;
}

.invoice-word {
	color: #0c3b5c;
	font-size: 40px;
	font-weight: 700;
}

.marketInvoice-table {
	table {
		border-collapse: collapse;
	}
	td {
		border: 1px solid #0c3b5c;
		padding: 0.5rem;
	}
	th {
		border: 1px solid #0c3b5c;
		padding: 0.5rem;
	}
	thead th {
		border: 1px solid #0c3b5c;
		padding: 0.5rem;
	}
	tr:first-child td {
		border-top: 0;
	}
	tr td:first-child {
		border-left: 0;
	}

	tr td:last-child {
		border-right: 0;
	}
	tr th:first-child {
		border-left: 0;
	}
	tr th:last-child {
		border-right: 0;
	}
	.last-td-border {
		border: 0 !important;
		border-bottom: 1px solid #0c3b5c !important;
	}

	.remove-last-td-border {
		border-bottom: 0 !important;
	}
}

.bordered-img-holder {
	display: flex;
	align-items: center;
	height: 20vh;
}
.A4-height {
	min-height: 29.7cm !important;
}
.width-10 {
	width: 13% !important;
}

.company-logo-font {
	font-size: 2rem !important;
}
@media print {
	html,
	.bg-gray-100 {
		background-color: #fff !important;
	}
	.invoice {
		height: 100vh !important;
	}
	.container-wrapper {
		height: 97vh !important;
	}
}

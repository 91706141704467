.pricing-box {
	padding: 0 0.5rem;
	font-size: $text-caption;

	.radios {
		display: flex;
		padding: 0 0.8rem;
		font-size: $text-headline;
		> div {
			// padding-#{var(--right)}: 6rem;
			padding-block-start: 6rem;
			input,
			label {
				cursor: pointer;
			}
		}
	}
}

//Adut price
.adult-price {
	.head-price {
		font-size: $text-caption;
		background: $dark-gray;
		padding: 0.25rem 1rem;
		margin: 0 0.3rem;
		border-radius: 0.25rem;
		text-align: center;
		color: $white;
	}
	.head-price-height {
		padding: 1.125rem 1rem;
		height: 52px;
		font-size: 14px;
	}
	//Room Count
	.room-count {
		width: 100%;
		display: flex;
		justify-content: flex-start;
		.room-item {
			padding: 0.1rem 0.2rem;
			margin: 0.3rem;
			color: $dark-gray;
			background: #b9bdc2;
			// width: 10.5%;
			text-align: center;
			font-size: $text-caption;
			width: 100%;
		}
	}

	.room-item .invalid {
		border: 1px solid #ea1414 !important;
		color: #ea1414;
	}

	//Room Price
	.room-price {
		width: 100%;
		display: flex;
		justify-content: flex-start;
		.room-item {
			margin: 0.3rem;
			color: $dark-gray;
			// width: 10%;
			width: 100%;

			height: 50px;
			text-align: center;
			overflow: hidden;
			border: 1px solid $gray-150;
			display: flex;
			align-items: center;
			justify-content: center;
			input {
				border: none;
				width: 100%;
				height: 100%;
				text-align: center;
				color: $dark-gray;
				font-weight: 700;
				// input placeholder
				&::placeholder {
					color: #acacac;
					font-weight: normal;
				}

				&:focus {
					border: 1px solid #1777fb;
				}
			}
			.bg-primary {
				background-color: #1777fb3e !important;
				color: #1777fb;
				&::placeholder {
					color: #1777fb;
					font-weight: 700;
				}
			}
		}
	}
}

.head-preakdown {
	font-size: $text-caption;
	background: $dark-gray;
	padding: 0.25rem;
	border-radius: 0.25rem;
	text-align: center;
	color: $white;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

//Extra Price
.extra-price {
	.extra-head {
		display: flex;
		justify-content: flex-start;
		// height: 100%;
		.head-item {
			padding: 0.5rem 1rem;
			margin: 0 0.5rem;
			color: $white;
			background: $dark-gray;
			text-align: center;
			font-size: $text-caption;
			width: 100%;
			text-transform: uppercase;
			// flex: auto;
			@media (max-width: 1920px) and (min-width: 1500px) {
				font-size: 14px;
				// padding: 0.5rem 1.2rem;
			}
		}
		// .head-item-product {
		// 	width: 50%;
		// }
	}
}

//Extra Input
.extra-input {
	.room-item {
		// width: 30% !important;
		margin: 0 0.5rem !important;
	}
}

//Currency Price
.currency-price {
	display: flex;
	justify-content: space-between;
	height: 50px;
	align-items: center;
	background: $gray-100;
	border: 1px solid $gray-150;
	padding: 0.5rem;
	color: $dark-gray;
	border-radius: 0.25rem;
	select {
		width: 55px;
		color: #acacac;
		border: 1px solid $gray-400;
		border-radius: 5px;
	}
	h6 {
		text-transform: capitalize;
	}
}

// .full-package,
// .package-breakdown {
//   display: none;
// }

//Start Package Breakdown
.question-mark {
	color: $white;
	background: $gray-400;
	display: inline-block;
	border-radius: 50%;
	width: 20px;
	height: 20px;
	line-height: 20px;
	text-align: center;
	margin: 0 0.4rem;
}

.img-circle-wrapper {
	display: flex;
	-ms-flex-pack: center;
	justify-content: center;
	-ms-flex-align: center;
	align-items: center;
	width: 35px;
	height: 35px;
	border-radius: 50%;
	line-height: 35px;
	background-color: $dark-gray;
	margin: 0px 5px;
	padding: 10px;
	color: $white;
	i,
	.icx {
		font-size: $text-title3 - 3;
	}
	img {
		width: 25px;
		height: 25px;
		object-fit: contain;
	}
}

.standard-expenses {
	display: flex;
	align-items: center;
	.add-icon {
		margin: 0 0.5rem;
		cursor: pointer;
		width: 10%;
		i {
			width: 25px;
			height: 25px;
			line-height: 25px;
			background: $green;
			color: $white;
			text-align: center;
			border-radius: 50%;
		}
	}
	.control-field {
		width: 90%;
	}
	input {
		max-width: 98px;
		@media (max-width: 1920px) and (min-width: 1500px) {
			max-width: 195px;
		}
	}
	select {
		position: absolute;
		right: 0.5rem;
		width: 55px;
		color: #acacac;
		border: 1px solid $gray-400;
		border-radius: 2px;
		[dir="rtl"] & {
			right: auto;
			left: 55px;
		}
	}
}
//End Package Breakdown

input[type="text"]:disabled {
	background: $gray-150;
}

.w-90p {
	width: 90px !important;
}

.product-element {
	margin: calculateRem(15px) 0;
	background-color: $white;
	border-radius: calculateRem(5px);
	&.addBorder {
		border: 1px solid #dfdfdf !important;
	}

	&__head {
		background-color: #ebebeb;
		border-radius: calculateRem(10px);
		padding: 8px 0px;
		&.addBorder {
			border-radius: 8px 8px 1px 1px;
		}
	}

	&__body {
		padding: calculateRem(20px);
	}

	&__icon {
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: $blue;
		color: $white;
		border-radius: 100px;
		width: calculateRem(60px);
		height: calculateRem(60px);
		margin: calculateRem(10px);
		& img {
			width: 25px;
		}

		& i {
			font-size: calculateRem(30px);
		}
	}
}

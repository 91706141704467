.list-container {
	max-width: 1300px;

	padding-right: 15px;
	padding-left: 15px;
	margin-right: auto;
	margin-left: auto;
}
.set-w-15 {
	width: 15% !important;
}

.set-w-11 {
	width: 13% !important;
}
.txt-blue {
	color: #0c3b5c;
	font-weight: 700;
}
.bg-details {
	background-color: #faf2e8;
}
.bg-overveiw {
	background-color: #f1f4f7;
}
.custom-icon-size {
	font-size: 14px !important;
}
.custom-icon-color {
	color: #cb9a51;
}
.terms-color {
	color: #0c3b5c !important;
}
.payment-box {
	background-color: #f7f7f7;
	border-radius: 10px;
}
.must-box {
	background-color: #cfead5;
	color: #28a745;
	border-radius: 10px;
}
.clear-filter {
	border: 1px solid #666666;
	font-size: 15px;
}
[dir="ltr"] .hotel-found {
	border-left: 3px solid #cb9a51;
	padding-left: 8px;
}
[dir="rtl"] .hotel-found {
	border-right: 3px solid #cb9a51;
	padding-right: 8px;
}
.error-model-title {
	font-size: 17px;
	font-weight: 600;
	color: #1b1e21;
}
.pointer {
	cursor: pointer;
}

.fix-title {
	white-space: nowrap;
	width: 120px;
	overflow: hidden;
	text-overflow: ellipsis;
}

@import "../../base/variables";
#sideNav {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100%;
  width: 70px;
  top: 0;
  left: 0;
  background-color: #FFF;
  text-align: center;
  box-shadow: 0px 2px 12px #0000003E;
  z-index: 1001;
  transition: .7s;
}
[dir="rtl"] #sideNav {
  right: 0;
  text-align: right;
}

#sideNav.opend {
  width: $opendNav;
  max-width: $opendNavMax;
}
.sidenav_list {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  text-align: left;
}

.sidenav_header {
  margin-top: 2.5rem;
  margin-bottom: 1rem;
  font-weight: bold;
  font-size: 1rem;
  padding: 0 1rem;
  transition: .4s;
  transition-delay: .2s;
  text-align: start;
}

.sidenav_item {
  position: relative;
  display: block;
}

.sidenav_link {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: start;
  width: 100%;
  height: 50px;
  margin: .25rem 0;
  color: $gray-900;
}

.sidenav_item .dash-text{
  margin-inline-start: 1rem;
  color: $gray-900;
}

.sidenav_icon {
  flex: 0 0 22px;
  width: 22px;
}

.sidenav_icon svg,
.sidenav_arrow svg{
  width: 100%;
  fill: $gray-900;
}

.sidenav_arrow svg  path {
  stroke: $gray-900;
}

.sidenav_arrow {
  position: absolute;
  inset-inline-end: 10px;
  width: 7px !important;
}
[dir="rtl"] .sidenav_arrow  {
  transform: scale(-1, 1);
}

.dash-text {
  // display: none;
  visibility: hidden;
  opacity: 0;
  width: 0;
  transition: .4s;
  font-weight: 700;
}

///////////////////////////////////// if the nav opend  ///////////////////////////////////////////
.opend .sidenav_link {
  justify-content: start;
}
.opend .sidenav_link {
  padding: 1rem;
}
.opend .dash-text {
  // display: block;
  visibility: visible;
  opacity: 1;
  width: auto;
  transition: .4s;
  transition-delay: .2s;
}
.opend .sidenav_link:hover, 
.opend .sidenav_link:hover svg,
.opend .sidenav_link:hover .dash-text {  
  color: $orange-500;
  fill: $orange-500 !important;
  transition: .4s;
  transition-delay: .2s;
}
.opend .sidenav_link:hover .sidenav_arrow svg  path {
  stroke: $orange-500;
  transition: .4s;
  transition-delay: .2s;
}
///////////////////////////////////// if the nav closed  ///////////////////////////////////////////
.closed .sidenav_dropdown,
.closed .sidenav_arrow  {
  // display: none;
  visibility: hidden;
  opacity: 0;
  width: 0;
}

.closed .sidenav_item:hover {
  width: $opendNav;
}
.closed .sidenav_link {
  padding-inline-start: 20px;
}
.closed .sidenav_link:hover .sidenav_dropdown {
  position: absolute;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #fff;
  box-shadow: 0px 2px 12px #0000003E;
  left: 0;
  z-index: -1;

  visibility: visible;
  opacity: 1;
  width: 100%;
}
.closed .sidenav_link:hover .dash-text {
  // display: block;
  visibility: visible;
  opacity: 1;
  padding: 0 2.5rem;
  width: 100%;
  transition: none;
}

.closed .sidenav_link:hover .sidenav_arrow {
  // display: block;
  visibility: visible;
  opacity: 1;
  width: auto;
}
.closed .sidenav_header {
  text-align: center;
}
// active
.sidenav_link.active {
  position: relative;
}
.sidenav_link.active::after {
  content: "";
  position: absolute;
  height: 70%;
  top: calc(50% + 3px) ;
  transform: translateY(-50%);
  background-color: $orange-500;
  width: 3px;
  inset-inline-end: 0;
}
.sidenav_link.active ,
.sidenav_link.active svg,
.sidenav_link.active .dash-text {
  color: $orange-500;
  fill: $orange-500 !important;
}
.sidenav_link.active .sidenav_arrow svg path{
  stroke: $orange-500;
}


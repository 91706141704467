.main-bar {
	margin: auto;
	max-width: 800px;
	width: 78%;

	&__progress {
		outline-width: 5px;
		outline-color: transparent;
		outline-style: solid;
		height: 2px;
	}

	&__box {
		display: flex;
		justify-content: space-between;
		margin-top: -32px;
	}

	&__item {
		width: 57px;
		height: 57px;
		line-height: 50px;
		border: 1px solid $gray-100;
		border-radius: 50%;
		text-align: center;
		position: relative;
		background: $white;
		color: $gray-300;

		&.active {
			background-color: #0c3b5c;
			color: white;
		}

		&.current {
			background-color: #cb9a51;
		}
		.progress-text {
			position: absolute;
			left: -1rem;
			width: 90px;
			font-size: 12px;
			top: 2.2rem;
		}
		.fa-plane {
			[dir="rtl"] & {
				transform: rotateZ(180deg);
			}
		}
	}
}

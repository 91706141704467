.dashboard-section {
	position: fixed;
	top: 78px;
	width: 100%;
	height: 100vh;
	background: rgb(255 255 255 / 95%);
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 999999999999999999999999999999999999999999;

	transition: opacity 5s ease-in-out;
	z-index: 999;

	.dashboard-box {
		// margin: 0rem 8rem;
		padding: 5rem 0;
		margin: auto;
		width: 80%;
	}
	.title-box {
		display: flex;
		justify-content: space-between;
		button {
			font-size: 12px;
			padding: 0.5rem 2rem;
		}
	} //title-box
	.dashboard-ul {
		display: flex;
		// justify-content: space-around;
		// justify-content: space-between;
		flex-wrap: wrap;
		// margin: 3rem 0;
		.active {
			.img-box {
				background: #1777fb;
			}
			.dash-text {
				color: #1777fb;
			}
		}
		a {
			-webkit-transition: all 0.5s ease-in-out;
			-moz-transition: all 0.5s ease-in-out;
			-o-transition: all 0.5s ease-in-out;
			display: inline-block;
			width: 20%;
			/* margin-top: 2rem; */
			background: #fff;
			margin: 15px;
			padding: 30px 0;
			border: 1px solid rgb(128 128 128 / 43%);
			border-radius: 8px;

			&:hover {
				background: rgb(254, 253, 251);
				background: linear-gradient(
					180deg,
					rgba(254, 253, 251, 1) 0%,
					rgba(244, 230, 212, 1) 40%,
					rgba(235, 207, 173, 1) 100%
				);
			}
			&:hover .dash-text {
				font-weight: bold;
			}
		}
		li {
			text-align: center;
		}
		.img-box {
			position: relative;

			-webkit-transition: all 0.3s ease-in-out;
			-moz-transition: all 0.3s ease-in-out;
			-o-transition: all 0.3s ease-in-out;
			margin: 11%;
			.bell-icon {
				position: absolute;
				top: 2px;
				right: 2px;
				background: #fe6c72;
				display: inline-flex;
				width: 25px;
				height: 25px;
				line-height: 25px;
				border: 2px solid #fff;
				border-radius: 50%;
				color: #fff;
				padding: 0.3rem;
				img {
					width: 100%;
					height: 100%;
					object-fit: contain;
				}
			}
			img {
				width: 50px;
				height: 50px;
				object-fit: contain;
			}
		}
		.dash-text {
			margin: 0.8rem 0;
			color: #134060;
			font-weight: normal;
			-webkit-transition: all 0.3s ease-in-out;
			-moz-transition: all 0.3s ease-in-out;
			-o-transition: all 0.3s ease-in-out;
		}
	} //dashboard-ul
}

@keyframes fadeout {
	0% {
		opacity: 1;
		transform: scale(1);
	}
	100% {
		opacity: 0;
		transform: scale(0.9);
	}
}

@keyframes fadein {
	0% {
		opacity: 0;
		transform: scale(1.1);
	}
	100% {
		opacity: 1;
		transform: scale(1);
	}
}

//
// .toggleOut {
// 	animation: fadeout 0ms;
// 	opacity: 0;
// 	transition: 0.5s;
// 	display: none;
// }

// .toggleIn {
// 	animation: fadein 0ms;
// 	opacity: 1;
// 	transition: 0.2s;
// }

.toggleOut {
	animation: fadeout 0ms;
	transform: scale(0) rotateX(0);
}

.toggleIn {
	animation: fadein 500ms;
	opacity: 1;
}

.menu-img {
	width: 20px;
}
.navItem-dashboard-container {
	position: relative;
}
.navItem-dashboard-container .soon {
	background: #dea237;
	background: linear-gradient(180deg, #daa249 0%, #cc9a50 100%);
	padding: 2px 10px;
	font-size: 12px;
	position: absolute;
	top: -8px;
	inset-inline-start: 5px;
	color: #fff;
	border-radius: 5px;
	left: 30%;
}

@media (min-width: 320px) and (max-width: 425px) {
	.dashboard-box {
		width: 100% !important;
	}
	.dashboard-ul {
		margin-top: 25px !important;
		width: 75% !important;
		a {
			width: 30% !important;
			margin: 5px !important;
			padding: 5px 0 !important;
		}
	}
	.main-menu {
		.custom-main {
			position: absolute !important;
			top: 100% !important;
			left: 20% !important;
			right: 52% !important;
			z-index: 100 !important;
			width: 175px !important;
		}
	}
}

/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */
.backOffice-wrapper {
	display: flex;
	width: 100%;
	align-items: stretch;
	color: $blueBlack;

	// .btn:hover {
	// 	background: #17a2b8;
	// }

	.sidebar {
		min-width: 250px;
		max-width: 250px;
		background: $blueBlack;
		color: #adb5bd;
		margin-left: -250px;
		transition: all 0.5s;
		[dir="rtl"] & {
			margin-left: 0;
			margin-right: -250px;
		}
		min-height: 100vh;
	}

	.sidebar.is-open {
		margin-left: 0;
		transition: 0.5s;
		[dir="rtl"] & {
			margin-left: initial;
			margin-right: 0;
		}
	}

	.sidebar-header {
		background: $blueBlack;
		color: #adb5bd;
	}

	.sidebar-header h3 {
		color: $white;
		padding: 1em;
	}

	.sidebar ul p {
		color: $white;
		padding: 10px;
	}

	// .collapse.show,
	// .collapsing {
	// 	background: #313b4c;
	// }

	.main-menu {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0 1rem;
		color: #adb5bd;
		i {
			[dir="rtl"] & {
				transform: rotate(180deg);
			}
		}
		&:hover {
			background: #313b4c;
			transition: 0.2s;
			cursor: pointer;
			.nav-item,
			i {
				color: $white;
			}
		}
	}
	.activeClass {
		background: #313b4c;
		color: $white;
	}

	.office-navbar {
		.nav-item {
			font-weight: 300;
			display: block;
			padding: 0.5rem 1rem;

			a {
				color: $white !important;
			}
		}

		.nav-item.open {
			color: #e9ecef;
			background: #313b4c;
		}

		.nav-item.active {
			color: #e9ecef;
			background: #2d3646 !important;
		}

		// .nav-item:hover {
		// 	background: #313b4c;
		// 	color: $white;
		// 	transition: 0.2s;
		// }

		.nav-item .svg-inline--fa {
			color: inherit;
		}
	}

	li a.dropdown-toggle::after {
		display: inline-flex;
		position: relative;
		left: 60%;
		top: 10%;
	}

	.sidebar-header > button {
		position: relative;
		float: right;
		margin: 0.5em;
		font-size: 2rem;
		cursor: pointer;
		display: none;
	}

	/* ---------------------------------------------------
			CONTENT STYLE
	----------------------------------------------------- */
	.content {
		padding: 10px;
		margin-left: 0;
	}

	@media only screen and (max-width: 575.98px) {
		body {
			overflow: hidden;
		}

		.content.is-open {
			display: none;
			transition: all 0.5s;
		}

		.sidebar.is-open {
			min-width: 100%;
			max-width: 100%;
			margin-left: 0;
			transition: all 0.5s, height 0s;
			[dir="rtl"] & {
				margin-left: initial;
				margin-right: 0;
			}
		}

		.sidebar.is-open > .sidebar-header button {
			display: block;
		}

		li a.dropdown-toggle::after {
			display: inline-block;
			position: relative;
			left: 68%;
		}
	}

	//Tables
	.back-office-table {
		td,
		th {
			padding: 0.5rem;
			vertical-align: middle;
			border-top: none;
		}
		td {
			&:last-child {
				border-top-right-radius: 0px;
				border-bottom-right-radius: 0px;
				[dir="rtl"] & {
					border-top-right-radius: 0;
					border-bottom-right-radius: 0;
					border-top-left-radius: 0px;
					border-bottom-left-radius: 0px;
				}
			}
		}
		th {
			&:first-child {
				border-top-left-radius: 0px;
				border-bottom-left-radius: 0px;
				[dir="rtl"] & {
					border-top-left-radius: 0;
					border-bottom-left-radius: 0;
					border-top-right-radius: 0px;
					border-bottom-right-radius: 0px;
				}
			}
			&:last-child {
				border-top-right-radius: 0px;
				border-bottom-right-radius: 0px;
				[dir="rtl"] & {
					border-top-right-radius: 0;
					border-bottom-right-radius: 0;
					border-top-left-radius: 0px;
					border-bottom-left-radius: 0px;
				}
			}
		}
		thead {
			background-color: $white;
			color: $dark-blue;

			th {
				border-bottom: 2px solid $white;
				font-weight: 600;
				color: #a7afb7;
				font-weight: 700;
			}
		}
		tbody {
			tr:nth-child(even) {
				background: $white;
				box-shadow: 0px 0px 1px #7d7d7d;
				border-radius: 8px;
				overflow: hidden;
			}

			tr:nth-child(odd) {
				background: $whiteBlue;
				box-shadow: 0px 0px 1px #7d7d7d;
				border-radius: 8px;
				overflow: hidden;
			}
		}
		i {
			cursor: pointer;
		}
	}

	.invitation-head {
		border-bottom: 1px solid #eae8e9;
		padding-bottom: 0.4rem;
	}

	.icon-round {
		border: 1px solid #00b437;
		width: 30px;
		height: 30px;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.main-tabs .main-nav .nav-item .nav-link {
		background-color: #a7afb7;
		color: #fff;
		min-height: 55px;
	}
	.main-tabs .main-nav .nav-link.active {
		min-height: 55px;
	}
	.main-tabs .main-nav .nav-link.active::after {
		right: 45%;
	}
	.main-tabs .main-nav .nav-item {
		margin: 0;
	}
	.nav-tabs .nav-link.active {
		background-color: $blueBlack !important;
	}
	.nav-tabs .nav-link {
		border-radius: 0;
	}
	.main-filter {
		padding: 1rem 0;
	}

	.border-r-0 {
		border-right: 0;
		outline: none;
		box-shadow: none;
		&:focus {
			border-color: #ced4da;
		}
		[dir="rtl"] & {
			border-right: 1px solid #ced4da;
			border-left: 0;
			border-radius: 0;
		}
	}

	.input-group-text {
		[dir="rtl"] & {
			border-right: 0;
			border-radius: 0;
		}
	}

	.control-field {
		border-radius: 0;
	}
} //backOffice-wrapper

//team-management
.team-management__back-office {
	.product-build__product-collpase {
		.button-collapse {
			.title-style-container {
				.right-items-container {
					width: 96%;
					background: $blueBlack;
					height: 100%;
					.product-content-items {
						color: #fff;
						font-size: 16px;
						.img-circle-wrapper {
							background-color: #fff;
							span {
								color: $blueBlack;
							}
						}
					}
				}
				.icon-items-section {
					width: 4%;
					justify-content: center;
					background: #9d9d9d;
					height: 100%;
					cursor: pointer;
				}
			}
		}
	}
}

.List-passengers-header {
	background-color: #fafafa;
}
.insert-group-search {
	background-color: #0c3b5c;
}
.insert-group-checkbox:checked {
	accent-color: #b18849;
	color: white;
}
.online-vise-img {
	width: 20%;
}

.txt-gold-onlinevisa {
	color: #cb9a51;
}
.custom-file {
	opacity: 0;
	cursor: pointer;
	position: absolute;
	z-index: 1;
	right: 0;
	left: 0;
	top: 0;
}

.file-btn {
	position: relative;
	color: white;
	font-weight: 500;
	border: none;

	width: 25%;
	padding: 0.25rem;
	font-size: 16px;
	cursor: pointer;
}

.onlineVisa-clear-bottom {
	border: 1px solid #666666;
	padding: 10px;
}
.scan-mrz-onlineVisa {
	border-radius: 10px;
	border: 1px dashed #cb9a51;
	background-color: #f7f7f7;
}

.online-visa-noResult {
	color: #979797 !important;
	font-size: 20px !important;
}
.online-visa-noResult-tr {
	height: 25vh !important;
	display: flex;
	justify-content: center;
	align-items: center;
}
.customPaginationActive {
	background-color: #112e53 !important;
	color: white !important;
}
.custom-file-mrz {
	opacity: 0;
	cursor: pointer;
	position: absolute;
	z-index: 1;
	right: 0;
	left: 0;
	top: 0;
}

.file-btn-mrz {
	border-radius: 10px;
	position: relative;
}

.custom-main {
	position: absolute;
	top: 100%;
	left: 45%;
	right: 45%;
	z-index: 100;
	width: 175px;
}
.custom-notification {
	width: 45px;
	height: 45px;
	border-radius: 50%;
	background-color: #ebebeb;
	display: flex;
	justify-content: center;
	align-items: center;
}
.TechnicalSupport {
	background-color: #00a042;
	color: white;
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
	text-align: center;
	height: 15vh;
	writing-mode: vertical-lr;
	transform: rotate(180deg);
}

.TechnicalSupport:hover {
	color: white;
}
.TechnicalSupport-width {
	width: 1%;
	height: 15vh;
	top: 40%;
	position: absolute;
}
.logo-header{
	display: none;
	}
		.img-fluid-logo{
				width: 100%;
				max-height: 70px;
				object-fit: contain;
			}

	@media(min-width:371px) and (max-width:576px) {
		.logo-header{
			width: 75px;
			height: 75px;
			object-fit: contain;
			display: block;
	}
	}
.product-build {
	background-color: $gray-100;
	// Start Header
	&__header {
		width: 100%;
		min-height: 150px;
		background-image: url("../../../assets/images/product-builder/product-header.png");
		background-repeat: no-repeat no-repeat;
		background-position: center center;
		background-size: cover;
	}
	&__title {
		display: flex;
		justify-content: center;
		padding: 20px 0px;
		font-size: $text-title;
		color: $dark-gray;
		text-transform: uppercase;
	}
	&__inputs {
		width: 100%;
		height: auto;
		padding: 5px 10px;
		background-color: transparent;
		display: grid;
		grid-template-columns: 2fr 2fr 2fr 2fr 2fr 0.5fr;
		grid-template-rows: minmax(20px, auto);
		gap: 5px 5px;
	}
	// End Header

	// Product Header
	&__product-header {
		.product-name-item {
			font-size: 24px;
			span {
				color: #cb9a51;
			}
		}
		p {
			color: $dark-gray;
			font-size: 12px;
			// opacity: 0.5;
		}
	}

	// Start Tabs
	&__tabs {
		display: grid;
		grid-template-columns: repeat(12, 1fr);
		width: 100%;
		margin-top: 15px;
		// padding-#{var(--left)}: 10px;
		padding-inline-end: 10px;
		// height: 90vh;
	}
	&__leftside-menu {
		grid-column: span 2;
		.nav-tabs {
			border-bottom: none;
		}
		.nav-item {
			// min-height: 90px;
			width: 80%;
			text-align: center;
			margin-bottom: 20px;
			background-color: $gray-150;

			.nav-link {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 100%;
				height: 100%;
				cursor: pointer;

				.product-build-list-item {
					.text,
					i {
						color: $dark-gray;
					}
					i {
						font-size: $text-title3 + 10;
					}
				}
				&.disabled {
					cursor: no-drop;
				}
			}
		}
		.nav-tabs .nav-item.show .nav-link::after,
		.nav-tabs .nav-link.active::after {
			transform: rotate(180deg);
		}
	}
	&__tabs-content {
		grid-column: span 8;
		// background-color: white;
		width: 100%;

		//fadeIn Tabs
		.tab-pane.active {
			animation-name: tabFade;
			animation-duration: 500ms;
			animation-timing-function: linear;
		}

		.tab-pane {
			margin: 0 0.5rem;
		}

		// << styling scroll bar >> //
		&::-webkit-scrollbar-track {
			box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
			background-color: $white;
		}

		&::-webkit-scrollbar {
			width: 6px;
			background-color: $white;
		}

		&::-webkit-scrollbar-thumb {
			background-color: $blue;
		}

		@media (max-width: 1920px) and (min-width: 1500px) {
			// min-height: 65vh;
		}
		// min-height: 60vh;
		// << styling scroll bar >> //
	}

	&__product-list {
		grid-column: span 2;
		padding: 0px 10px;
		// display: none;

		.disabled-list {
			background-color: $gray-200 !important;
			cursor: no-drop !important;
			.img-circle-wrapper {
				background-color: #acacac !important;
			}
		} //Disabled List

		.product-btn {
			transform: translateX(var(--productListTranslate));
			animation-name: fadeIn;
			animation-duration: 1s;
			animation-fill-mode: forwards;

			&:nth-child(1) {
				animation-delay: 0.2s;
			}
			&:nth-child(2) {
				animation-delay: 0.3s;
			}
			&:nth-child(3) {
				animation-delay: 0.4s;
			}
			&:nth-child(4) {
				animation-delay: 0.5s;
			}
			&:nth-child(5) {
				animation-delay: 0.6s;
			}
			&:nth-child(6) {
				animation-delay: 0.7s;
			}
			&:nth-child(7) {
				animation-delay: 0.8s;
			}
			&:nth-child(8) {
				animation-delay: 0.9s;
			}
		}

		.product-item {
			padding: 10px 5px;
			background-color: $white;
			border: 1px solid #d8dbde;
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			align-items: center;
			border-radius: 5px;
			cursor: pointer;
			margin: 5px 0;
			text-align: var(--left);
			.img-circle-wrapper {
				display: flex;
				-ms-flex-pack: center;
				justify-content: center;
				-ms-flex-align: center;
				align-items: center;
				width: 40px;
				height: 40px;
				border-radius: 50%;
				line-height: 40px;
				background-color: $blue;
				margin: 0px 5px;
				padding: 10px;
				color: $white;
				i,
				.icx {
					font-size: $text-headline;
				}
				img {
					width: 25px;
					height: 25px;
					object-fit: contain;
				}
			}
			.product-text {
				display: flex;
				flex-direction: column;

				.subtitle {
					font-size: 12px;
					color: $dark-gray;
					font-weight: lighter;
				}
				.title {
					font-size: 12px;
					color: $dark-gray;
					font-weight: 700;
				}
			}
		}
	}
	// End Tabs

	// Data Empty
	&__product-no-data {
		min-height: 25vh;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		color: #c6cbd0;
		h4 {
			margin-top: 10px;
			font-size: $text-headline;
		}
	}

	// Product Collapse
	&__product-collpase {
		// overflow: hidden;
		margin-top: 30px;
		margin-bottom: 30px;
		border-radius: 10px;

		.button-collapse {
			background-color: #ebebeb;
			// min-height: 100px !important;
			display: flex;
			position: relative;
			// padding: 10px 0 10px 24px;
			/* 			border: 1px solid #c8c7c7;
 */
			border-radius: 10px;
			margin-left: 10px;
			width: -webkit-fill-available;
			// z-index: 1;

			label {
				color: #3f4f61;
				// text-transform: uppercase;
			}

			.title-style-container {
				display: flex;
				align-items: center;
				width: 100%;
				font-size: $text-caption;
				.right-items-container {
					min-width: 10%;
					width: 98%;
					display: flex;
					flex-direction: row;
					justify-content: flex-start;
					align-items: center;
					.img-circle-wrapper {
						display: flex;
						justify-content: center;
						align-items: center;
						width: 50px;
						height: 50px;
						border-radius: 50%;
						line-height: 50px;
						background-color: $blue;
						margin: 0px 10px;
						padding: 20px;
						position: relative;
						z-index: 1;
						span {
							font-size: $text-title2;
							color: #fff;
						}
					}
				}
				.icon-items-section {
					.vertical {
						height: 100%;
						position: absolute;
						top: 0;
						// right: 55px;
						border-left: 1px solid #c8c7c7;
					}
					.fa-trash-alt {
						margin-top: 0px;
						margin-right: 5px;
						margin-left: 5px;
						color: $red;
						font-weight: normal;
					}
					i {
						font-size: $text-title3;
						cursor: pointer;
					}
				}
			}
		}
		.card-body {
			padding: 0.5rem;
		}

		//End Collpase Styling

		// Start Room Count
		.room-count {
			.room-box {
				.room-item {
					padding: 0.5rem 1em;
					border-radius: 5px;
					border: 1px solid $gray-150;
					background: #f4f4f4;
					margin-bottom: 0.4rem;
					// display: flex;
					align-items: center;
					.text,
					i {
						color: $dark-gray;
						font-weight: 700;
					}
				}
				.room-count {
					padding: 0.5rem 1em;
					border-radius: 5px;
					border: 1px solid $gray-150;
					background: $white;
					margin-bottom: 0.4rem;
					text-align: center;
					.text {
						color: $gray-300;
					}
				}
			}
		}
		// End Room Count

		//Notes
		.notes {
			.input-group {
				max-height: fit-content !important;
			}
		}
	}

	// Itenary Tab
	&__itenary-content {
		.itenary-box {
			position: relative;
			overflow: visible;
			&:after {
				position: absolute;
				top: 50px;
				left: 36px;
				height: 100%;
				content: "";
				border: 1px dashed #c3c3c3 !important;
				[dir="rtl"] & {
					left: auto;
					right: 36px;
				}
			}
			&:first-child {
				.button-collapse {
					/* 					border-radius: 5px 5px 0 0;
 */
				}
			}

			&:last-child {
				.button-collapse {
					/* 					border-radius: 0 0 5px 5px;
 */
				}
				&::after {
					border: none !important;
				}
			}

			//Start Animation Items
			transform: translateX(150%);
			animation-name: fadeInUp;
			animation-duration: 1s;
			animation-fill-mode: forwards;

			&:nth-child(1) {
				animation-delay: 0.2s;
			}
			&:nth-child(2) {
				animation-delay: 0.3s;
			}
			&:nth-child(3) {
				animation-delay: 0.4s;
			}
			&:nth-child(4) {
				animation-delay: 0.5s;
			}
			&:nth-child(5) {
				animation-delay: 0.6s;
			}
			&:nth-child(6) {
				animation-delay: 0.7s;
			}
			&:nth-child(7) {
				animation-delay: 0.8s;
			}
			&:nth-child(8) {
				animation-delay: 0.9s;
			}
			&:nth-child(9) {
				animation-delay: 1s;
			}
			//End Animation Items

			.button-collapse {
				cursor: auto;
			}
		} //itenary-box
	}

	// Button Actions
	&__button-actions {
		grid-column: 1 / -1;
		width: 100%;
		height: auto;
		display: grid;
		grid-template-columns: repeat(12, 1fr);
		grid-template-rows: auto;
		margin: 0px 0px;
		background: #f4f4f4;

		.actions-buttons-group {
			grid-column: span 8;
			padding: 5px 0px;
			height: auto;
			margin: 10px 0px;

			/// style buttons

			.cancel-product-btn {
				min-width: 125px;
				margin: 0px 5px 0 0;
				background-color: $white;
				border: 1px solid #696e72;
			}
			.save-product-btn {
				min-width: 250px;
				margin: 0px 5px;
			}
			.publish-product-btn {
				min-width: 250px;
				margin: 0px 5px;
				background: $green;
				color: #fff;
			}
		}
		.left-side {
			grid-column: span 2;
		}
		.right-side {
			grid-column: span 2;
		}
	}
	// Button Actions
} //product-build

// Start Room Count
.room-count {
	.room-box {
		.room-item {
			padding: 0.5rem 1em;
			border-radius: 5px;
			border: 1px solid $gray-150;
			background: #f4f4f4;
			margin-bottom: 0.4rem;
			// display: flex;
			align-items: center;
			.text,
			i {
				color: $dark-gray;
				font-weight: 700;
			}
		}
		.room-count {
			padding: 0.5rem 1em;
			border-radius: 5px;
			border: 1px solid $gray-150;
			background: $white;
			margin-bottom: 0.4rem;
			text-align: center;
			.text-input {
				color: $gray-300;
				width: 100%;
				outline: none;
				text-align: center;
				border: none;
			}
		}
	}
}
// End Room Count

// Active Tab
.k-product-build__leftside-menu .nav-tabs .nav-item.show .nav-link,
.k-product-build__leftside-menu .nav-tabs .nav-link.active {
	background: $main-bg-sbs !important;
	border: 1px solid transparent !important;
	color: #fff !important;
}
.k-product-build__leftside-menu .nav-link.active .text {
	color: #fff !important;
}
.product-build__leftside-menu .nav-tabs .nav-item.show .nav-link,
.product-build__leftside-menu .nav-tabs .nav-link.active {
	// background-color: #faf2e8 !important;
	// border: 1px solid #cb9a51 !important;
	// color: #cb9a51 !important;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;

	&::after {
		content: "";
		position: absolute;
		right: 0;
		border-width: 8px 8px 8px 8px;
		border-style: solid;
		// border-color: transparent #fff transparent transparent;
		border-top-color: transparent;
		// border-#{var(--right)}-color: #fff;
		border-inline-start-color: #fff;
		border-bottom-color: transparent;
		// border-#{var(--left)}-color: transparent;
		border-inline-end-color: transparent;

		[dir="rtl"] & {
			right: auto;
			left: 0;
		}
	}
	.product-build-list-item {
		.text {
			// color: #cb9a51 !important;
			font-weight: 700;
		}
	}
}

/********************** Start Animation ***************************/
// Animation Items
@keyframes fadeIn {
	0% {
		opacity: 0;
		transform: translateX(var(--percentage));
	}
	100% {
		opacity: 1;
		transform: translateX(0%);
	}
}

// FadeIn Tabs
@keyframes tabFade {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

// Animation Items
@keyframes fadeInUp {
	0% {
		opacity: 0;
		transform: translateY(100%);
	}
	100% {
		opacity: 1;
		transform: translateY(0%);
	}
}

.builder-wrapper {
	overflow: auto;
	overflow-x: hidden;
	// height: 70vh;
	border-radius: 0px 0px 5px 5px;
}

.product-content-items {
	width: 90%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: $dark-gray;
	font-size: $text-caption;
	.product-arrival {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 240px;

		.fa-circle {
			position: relative;
			color: $blue;
			&::after {
				content: "";
				position: absolute;
				top: 5px;
				// #{var(--left)}: 50%;
				left: 50%;
				width: 80px;
				height: 1px;
				background-color: $blue;
				z-index: 0;
				// [dir="rtl"] & {
				// 	left: auto;
				// 	right: 50%;
				// }
			}
		}
		i {
			font-size: $text-caption;
		}
		span {
			margin: 0 1.3rem;
		}
		.fa-plane {
			position: relative;
			color: $blue;
			&::after {
				content: "";
				position: absolute;
				top: 5px;
				// #{var(--right)}: 50%;
				right: 50%;
				width: 80px;
				height: 1px;
				background-color: $blue;
				z-index: 0;
				// [dir="rtl"] & {
				// 	right: unset;
				// 	left: 50%;
				// }
			}
		}
	}
	.days-box {
		display: flex;
		align-items: center;
		justify-content: space-between;
		background: #fff;
		border: 1px solid #dad9d9;
		padding: 0.7rem 0.4rem;
		border-radius: 5px;
		position: relative;
		width: 17%;
		min-width: 95px;
		@media (max-width: 1920px) and (min-width: 1500px) {
			width: 10%;
		}

		.icon {
			width: auto;
			i {
				color: #0184fe;
			}
		}
		.vertical {
			height: 100%;
			position: absolute;
			top: 0;
			border-left: 1px solid #c8c7c7;
			left: 30%;
			[dir="rtl"] & {
				left: auto;
				right: 30%;
			}
		}
		.nights {
			width: auto;
		}
	}
}

//custom filter
.main-filter {
	.filter-row {
		.col-min-1 {
			min-width: 115px;
		}
		.col-min-2 {
			min-width: 230px;
			max-width: 250px;
		}
		.control-field__label {
			text-transform: capitalize;
		}
	}
}
.custom-round {
	border-radius: 10px !important;
	background-color: #fff !important ;
}
.product-build__product-list .disabled-list {
	.circle-bg {
		background-color: white !important;
	}
}
.pro-name-itinerary {
	color: #2d2d2d !important;
	font-size: 20px !important;
	font-weight: normal;
}

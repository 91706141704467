.market-search {
	display: flex !important;
	justify-content: center !important;
	align-items: flex-start !important;
	background: $gray-100;
	//height: 100vh;

	.button-collapse {
		height: 44px !important;
		background: #fff;
	}
	.nav-tabs .nav-item.show .nav-link,
	.nav-tabs .nav-link.active {
		// background-color: #fff !important;
		// color: #cb9a51 !important;
		background: rgb(222, 162, 55);
		background: $main-bg-sbs;
		color: #fff;
		border: none !important;
		font-weight: 600 !important;
	}
	.heading,
	.tab-content,
	.nav-tabs {
		width: 100%;
		margin: 0;
	}
	.nav-tabs {
		background-color: #ebebeb;
	}
	.mybooking-input-wrapper {
		margin-bottom: 0.5rem;
		.fa-plane {
			transform: translate(25%, 0%) rotate(-45deg);
		}
	}

	.main-tabs {
		// min-height: 51vh;
		.main-nav {
			.nav-item {
				min-width: auto;
				min-height: 59px;
				position: relative;
				margin: 0;
				background: #cbcbcb;
			}
		}
	}

	.form-group {
		// margin-bottom: 0px !important;
		position: relative;

		.form-control-custom-input {
			height: 60px !important;
			padding: 15px 10px 0px 40px;
			border-radius: 0px;
			border: 0px;
			font-weight: bold;
			font-size: $text-caption;
			cursor: pointer;
		}

		.booking-label {
			position: absolute;
			top: 10px;
			// #{$left}: 45px;
			left: 45px;
			font-size: 11px;
			color: #acacac;
			[dir="rtl"] & {
				left: auto;
				right: 45px;
			}
		}

		.fa-calendar {
			@extend %fa-icons-style;
		}

		.fa-suitcase {
			@extend %fa-icons-style;
		}

		.fa-id-card {
			@extend %fa-icons-style;
		}

		i {
			@extend %fa-icons-style;
		}
	}

	%fa-icons-style {
		position: absolute;
		left: 2px;
		top: 35%;
		margin: 0px 0px 0px 5px;
		font-size: 20px;
		color: $gray-300;
		[dir="rtl"] & {
			left: auto;
			right: 0;
		}
	}

	.global-input {
		height: auto !important;
		padding: 15px !important;
		color: #354f5c;
		border-radius: 0px;
		border: 0px;
		font-weight: bold;
		font-size: 15px;
		cursor: pointer;
		width: 100%;
		border: 1px solid #dee2e6;
	}
}

.collapse-content {
	display: flex;
	justify-content: space-around;
	align-items: center;
	.room-collapse {
		display: flex;
		justify-content: space-around;
		margin-bottom: 1rem;
		span {
			width: 33.333%;
			text-align: center;
		}
		&:last-child {
			margin-bottom: 0;
		}
	}
	.full-input {
		width: 70px;
	}
}

.input-border {
	border: 1px solid #dee2e6;
}

.market-search-conatainer {
	position: relative;
	.main-tabs1 {
		.main-nav1 {
			//left: 10%;
			display: flex;
			flex-direction: column;

			.nav-item {
				a {
					background-color: #fff;
					color: #324356;
					position: relative;
					display: flex;
					align-items: center;
					justify-content: center;
					flex-direction: column;
					border-radius: 4px;
					border-color: #d8d8d8;
					height: 124px;
					width: 154px;
					transition: 0.5s;
					margin-right: 10px;
					i {
						display: none;
					}
				}
			}

			.nav-item {
				a.active {
					background-color: #faf2e8;
					color: #cb9a51;
					position: relative;
					display: inline-grid;
					align-items: center;
					justify-content: center;
					border-color: #cb9a51;
					z-index: 151;
					padding: 10px 10px;
					transition: 0.5s;
					margin-right: 10px;
					[dir="rtl"] & {
						margin-left: 10px;
					}
					p {
						font-weight: 700;
					}
					i {
						display: block;
						position: absolute;
						right: -6px;
						top: 10px;
						font-size: 16px;
						[dir="rtl"] & {
							right: unset;
							left: -6px;
							transform: rotate(180deg) !important;
						}
					}
				}
			}
		}
	}
}
.market-search-conatainer .main-tabs1 .main-nav1 .nav-item a.active {
	text-align: center;
}

.nav-tabs .nav-item.show .nav-link::after,
.nav-tabs .nav-link.active::after {
	display: none !important;
}

.search-conatiner {
	padding: 25px;
	background-color: red;
}
// .tab-content {
// 	background: white;
// 	padding: 15px;
// }
// .nav-tabs {
// 	background: white;
// 	padding: 15px;
// 	margin: 0rem auto !important;
// }

.market-search-conatainer .main-tabs1 .main-nav-contant {
	height: 100% !important;
}
.main-tabs {
	padding: 0.5rem;
	background: #fff;
	border-radius: 8px !important;
	border: 1px solid #dedede;
}
.main-tabs .main-nav .nav-item .nav-link {
	/* border: none; */
	// color: #324356;
	color: #343434;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 59px;
	min-width: 169px;
	border: 1px solid #dedede;
}
.search-title {
	font-size: 20px;
	font-weight: 700;
	color: #cb9a51;
}
.vacation-content.sub-tabs-vacation {
	.sub-tabs-content {
		background-color: #ebebeb;
		border-radius: 3px;

		.TAPactive {
			width: 50%;
			padding: 7px 5px;
			color: #979797;
			cursor: pointer;
			text-align: center;
		}
		.TAPactive.active {
			background-color: #faf2e8;
			color: #cb9a51;
			border-radius: 3px;
			font-weight: 600;
		}
	}
}
// .k-market-search-container {
// 	font-family: "Almarai", sans-serif!important;
// }
.k-market-search-container .soon {
	background: rgb(222, 162, 55);
	background: $main-bg-sbs;
	padding: 2px 10px;
	font-size: 12px;
	position: absolute;
	top: 5px;
	inset-inline-start: 5px;
	color: #fff;
	border-radius: 5px;
}
.k-market-search-container .market-search {
	border-radius: 10px;
}
.k-market-search-container .main-tabs1 .main-nav1 .nav-item a.active {
	background-color: transparent;
	background: rgb(222, 162, 55);
	background: $main-bg-sbs;
	color: #fff;
	background-repeat: no-repeat;
	background-size: contain;
}
.k-market-search-container .main-tabs1 .main-nav1 .nav-item a.active i {
	color: #ca7327;
}
.k-market-search-container .main-tabs1 .main-nav1 .nav-item a {
	border-radius: 10px;
}
.k-market-search-container .vacation-content.sub-tabs-vacation {
	.sub-tabs-content {
		background-color: #ebebeb;
		border-radius: 3px;
		margin-bottom: 0.5rem;

		.TAPactive {
			color: #343434;
			text-transform: uppercase;
		}
		.TAPactive.active {
			background: rgb(222, 162, 55);
			background: $main-bg-sbs;
			color: #fff;
		}
	}
}
.k-market-search-container .custom-control-input:checked ~ .custom-control-label::before,
.k-market-search-container .custom-control-input:focus {
	background-color: rgb(222, 162, 55) !important;
	border-color: rgb(222, 162, 55) !important;;
	box-shadow: none;
	outline: none;
}

.visa_search_result span{
	color: #28A745;
	font-weight: bold;
	font-size: 18px;
}
.pagination {
	display: flex;
	font-size: $text-caption;
	// flex-flow: row-reverse;
	span,
	.page-link {
		padding: 0.5rem;
		line-height: 1;
		color: #112e53;
		font-weight: bold;
		transition: all 0.3s ease-in-out;
		/* 		border-radius: 0.25rem;
 */
		.icx {
			transition: all 0.3s ease-in-out;
		}
		&:hover {
			background: $blue;
			color: $white;
			.icx {
				color: $white;
				transition: all 0.3s ease-in-out;
				outline: none;
			}
		}
		&:focus {
			box-shadow: 0 0 0 0.2rem rgba(0, 105, 217, 0.25);
		}
	}
}

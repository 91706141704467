.interest-result {
	background-color: $gray-100;
	padding: 10px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	// border: 1px solid #eae8e9;
	border-radius: 0.3125rem;
	height: 65px;
	.interest-data {
		line-height: 0.9rem;
		p {
			font-size: 13px !important;
			font-weight: 600;
		}
	}
	.dist {
		color: #acacac;
	}
	.fa-times {
		color: #000 !important;
		font-size: 15px !important;
	}
}
.interest {
	.tab-content {
		// min-height: 84vh;
		border: 1px solid #dfdfdf;
		border-top: 0 !important;
	}
	.nav {
		display: flex;
		flex-wrap: nowrap;
		padding-left: 0;
		margin-bottom: 0;
		list-style: none;
		height: 58px;
		.nav-item {
			min-height: 58px;
			width: 20%;
			text-align: center;
			margin-bottom: 0px !important;
			margin-top: 0px !important;
			background: #ebebeb !important;
		}
		.nav-link.active {
			height: 58px !important;
		}
		.nav-link {
			height: 58px !important;
			border: 0.3px solid #dfdfdf;
			border-bottom: 0;
			border-radius: 0 !important;
		}
	}
	.nav-tabs .nav-item.show .nav-link,
	.nav-tabs .nav-link.active {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		border-color: #fff !important;
		min-height: 55px !important;
		background-color: #fff !important;
		border-radius: 0 !important;
		border: 1px solid #dfdfdf !important;
		border-bottom: 0 !important;
	}
	.nav-tabs .nav-item.show .nav-link,
	.nav-tabs .nav-link.active .product-build-list-item span {
		//color: #cb9a51 !important;
		font-size: 18px;
		font-weight: 700;
	}

	.tab-icons {
		background-color: #ebebeb !important;
	}
	.line-height-1 {
		line-height: 1 !important;
	}
	.no-hotel {
		width: 100%;
		background: #f7f7f7;
		height: 350px;
		margin: auto;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		border-radius: 0.3125rem;
		p {
			color: #979797;
			margin: 15px 0;
		}
	}
	.btn-yallow {
		background-color: #cb9a51 !important;
		color: #fff;
		font-size: 16px;
	}
	.select-hr {
		display: flex;
		align-items: center;
		justify-content: space-between;
		p {
			color: #979797;
			font-size: 15px;
		}
		div {
			height: 1px;
			width: 88%;
			background: #dfdfdf;
		}
	}
}
@media (min-width: 800px) and (max-width: 1180px) {
	.product-build-list-item {
		font-size: 14px;
	}
	.interest .nav-tabs .nav-item.show .nav-link,
	.interest .nav-tabs .nav-link.active .product-build-list-item span {
		font-size: 15px;
	}
	.select-hr {
		p {
			font-size: 14px !important;
		}
		div {
			width: 87% !important;
		}
	}
}

.login-banner {
	// background: url("../../../assets/images/login_image.jpg");
	background-repeat: no-repeat;
	height: 100vh;
	background-color: $dark-blue;
	background-size: cover;

	&__logo {
		width: 50%;
	}
}

@media (min-width: 320px) and (max-width: 576px) {
	.login-page {
		.login-page-img {
			display: none !important;
		}
		.login-page-contant {
			width: 100% !important;
		}
		.signup-link {
			text-align: center !important;
			display: flex !important;
			justify-content: center !important;
		}
		.login-page-data {
			margin: 25px 0;
		}
	}

	.register-page {
		.register-page-img {
			display: none !important;
		}
		.register-controls {
			display: flex !important;
			justify-content: space-between !important;
			align-items: center;

			div {
				width: 50% !important;
			}
		}
		.register-email-confirmation {
			text-align: center !important;
		}
		.main-bar__item {
			width: 35px !important;
			height: 35px !important;
			img {
				width: 15px !important;
				margin-bottom: 19px !important;
			}
		}
		.main-bar__box {
			margin-top: -22px !important;
		}
		.sign-up-label {
			display: flex;
			align-items: center;
			flex-direction: column;
		}
		.text-title.text-orange {
			text-align: center !important;
		}
	}
}

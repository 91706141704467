.cart-items-wrapper {
	.hotel-detail-section {
		position: relative;
		.delete-item {
			position: absolute;
			top: 0;
			right: 0;
			cursor: pointer;
			[dir="rtl"] & {
				right: auto;
				left: 0;
			}
		}
	}
	.hotel-header {
		h6 {
			color: #354f5c;
			font-weight: 700;
		}
	}
	.total-price-box {
		padding-top: 0;
		height: 100px;
		background: #f9f9f9;
		border: 1px solid #e8e8e8;
	}

	.booking-summary {
		h5 {
			font-weight: 700;
			font-size: $text-headline;
			margin: 0.5rem 0;
		}
		.summary-box {
			display: flex;
			justify-content: space-between;
			font-size: $text-caption;
			padding: 0.2rem 0;
			&:last-child {
				border: none;
			}
		} //summary-box
		.total {
			display: flex;
			justify-content: space-between;
			font-weight: 700;
			font-size: $text-headline;
			background: #e8e8e8;
			padding: 0.3rem;
			border: 1px solid #dee2e6;
			color: #3c3c3c;
		}
	}

	.coupon-btn {
		margin: auto;
		text-transform: uppercase;
		padding: 0.5rem 1rem;
		display: flex;
		border: none;
		background-color: #acacac;
		color: $white;
	}
}

.text-decor {
	text-decoration: underline !important;
	color: $blue;
	transition: all 0.3s ease-in-out;
}

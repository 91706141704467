.create-app-table {
	background-color: transparent !important;
	direction: rtl !important;
	text-align: center;

	thead {
		background-color: #dedede !important;
		color: #324356 !important;
		th {
			border: 1px solid #dedede;
			min-width: 100px !important;
			text-align: center;
		}
	}
	tbody {
		td {
			background-color: #ffffff !important;
			border: 1px solid #e8e8e8;
			font-weight: 500;
		}
		.last-item {
			padding: 5px 15px !important;
			background-color: #dedede !important;
			color: #324356 !important;

			i {
				color: #a09e9e !important;
			}
		}
	}
}
.application__header {
	width: 100%;
	//height: 90px !important;
	background-image: url(../../../assets/images/product-builder/product-header.png);
	background-repeat: no-repeat no-repeat;
	background-position: center center;
	background-size: cover;
	color: #324356;
	.application__header-containt {
		height: 90px !important;

		display: flex !important;
		justify-content: space-between !important;
		align-items: center !important;
	}
}

.text-gray {
	color: #686d71;
}
.bg-gray {
	background-color: #686d71;
}
.border-radius-sm {
	border-radius: 5px;
	border: 1px solid rgb(219, 219, 219);
}
.container-custom {
	max-width: 1470px !important;
	margin: auto !important;
}
.traveler-tab {
	.main-tabs .main-nav .nav-item {
		min-height: 70px;
		display: flex;
		align-items: center;
		justify-content: center;
		background: #ffffff;
		width: 80px !important;
		margin-inline-start: 0.5rem;
	}
	.main-tabs .main-nav .nav-link.active {
		width: 80px;
		color: #ffffff !important;
		min-height: 70px;
	}
}
.passport-upload {
	background: $white;
	width: 100%;
	height: 150px;
	margin: 0.5rem 0;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	i {
		color: grey;
	}
}

.hotels-tab {
	.nav-link {
		padding: 7px 15px;
		background: white;
		color: #324356;
		margin: 0 2px;
	}
	.nav-link.active {
		background: #acacac !important;
		color: #fff !important;
		min-height: auto;
		&::after {
			display: none;
		}
	}
}
.nav-tabs {
	border-bottom: none !important;
}
.traverlers-content-items {
	background: #eae8e9;
	padding: 8px;
	border-radius: 5px;
	height: 490px;
	overflow: auto;

	.traverlers-data {
		background: #dedede;
		border: 1px solid #d4d3d3;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 6px;
		border-radius: 5px;
		margin-bottom: 2px;
		min-height: 58px;
		h6 {
			color: #686d71;
			font-size: 14px;
		}
		.hand-icon {
			width: 45px;
			height: 45px;
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 20px;
			color: white;
		}
		.icon-box {
			width: 45px;
			height: 45px;
			background: #acacac;
			text-align: center;
			border-radius: 50%;
			padding-top: 3px;
			color: white;
			i,
			p {
				font-size: 13px;
			}
			p {
				line-height: 0.5;
			}
		}
	}
	.active-drag {
		background: #0184fe;
		cursor: pointer;
		.head {
			h6 {
				color: white;
			}
		}
		.active-icon {
			background-color: white;
			color: #0184fe;
		}
	}
}

.rooms-content-items {
	background: #f4f4f4;
	border: 1px solid #ebe8e8;
	border-radius: 5px;
	padding: 10px 12px;
	box-shadow: 0px 3px 6px #00000029;
	height: 490px;
	overflow: auto;
	.room-item {
		background: white;
		border-radius: 5px;
		overflow: hidden;
		box-shadow: 0px 3px 6px #00000029;
		min-height: 225px;

		.room-head {
			display: flex;
			justify-content: space-between;
			padding: 7px;
			background: #eae8e9;
			font-size: 12px;
			.fa-trash-alt {
				color: #ff5f59;
			}
			.fa-edit,
			.add-bed {
				color: #0385fe;
			}
			.fa-unlock-alt {
				color: #b9b9b9;
			}
			ul {
				display: flex;
			}
			button {
				padding: 0px 3px;
			}
			i {
				font-size: 12px;
			}
		}
		.room-box {
			padding: 3px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			background: #dedede; //#dedede
			margin: 5px 8px;
			border-radius: 5px;
			.room-icon {
				color: #b0b0b0;
				cursor: pointer;
				font-size: 12px;
			}
			.room-data {
				color: #686d71; //dark grey
				font-size: 12px;
			}
			.gray-color {
				color: #686d71;
			}
		}
		.extra-bed-box {
			background-color: #0184fe;
			.room-data {
				color: #fff;
			}
			.room-icon {
				i {
					color: #fff;
					font-size: 19px;
					margin: 2px;
				}
			}
		}
		.extra-bed-title {
			color: #acacac;
			font-size: 12px;
			margin: 3px 7px;
		}
	}
	.bg-gray {
		background-color: #dddddd;
	}
}

.additional-services {
	.head {
		color: #324356;
		font-weight: 700;
	}
	.extra-item {
		border: 1px solid #cecece;
		border-radius: 5px;
		li {
			display: flex;
			justify-content: space-between;
			border-bottom: 1px solid #cecece;
			padding: 8px;
			&:last-child {
				border: none;
			}
			.title {
				color: #686d71;
			}
			.price {
				font-weight: 500;
			}
		}
	}
}

.accounting-item {
	padding: 12px 0;
	.head {
		padding-top: 15px;
		font-weight: 700;
		padding: 0 5px;
		color: #324356;
	}
	.account-item {
		display: flex;
		justify-content: space-between;
		background: #f4f4f4;
		border: 1px solid #d8dbde;
		border-radius: 5px;
		padding: 12px;
		.title {
			color: #686d71;
		}
		.price {
			font-weight: 700;
			color: #324356;
		}
	}
}

* {
	font-family: "Cairo", sans-serif;
}

.auto-show-more:hover{
	color: white !important;
}
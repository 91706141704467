// Directions
$dir: ltr;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;
@import "react-image-crop/src/ReactCrop.scss";
html {
    --right: right;
    --left: left;
    --dir: ltr;
    --productListTranslate: 150%;
    --percentage: 100%;
    --icx-left-solid-arrow: "\e903";
    --icx-right-solid-arrow: "\e904";
    --icx-arrow-right: "\e908";
}

@import "~bootstrap";
// Base Files
@import "base/variables";
@import "base/reset";
@import "base/functions";
@import "base/mixins";
@import "base/colors";
@import "base/typography";
@import "base/icomoon";
@import "base/general";
@import "base/custom-modal";
// Shared Components
@import "shared/all";
// Modules
@import "./modules/auth/all";
@import "./modules/ProductBuilder/all";
@import "./modules/Reservation/all";
@import "./modules/TeamManagement/all";
@import "./modules/UserProfile/all";
@import "./modules/MarketPlace/all";
@import "./modules/Payment/all";
@import "./modules/inventory/all";
@import "./modules/Dashboard/all";
@import "./modules/interests/all";
@import "./modules/backOffice/all";
@import "./modules/statistics/all";
@import "./modules/notifaction/all";
@import "./modules/custom/all";
@import "./modules/responsive/all";
@import "./modules/progressCircular/all";
@import "./modules/Application/all";
@import "./modules/Visa/all";
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@400;600;700&display=swap");
html[dir="rtl"] {
    --dir: rtl;
    --left: right;
    --right: left;
    --icx-left-solid-arrow: "\e904";
    --icx-right-solid-arrow: "\e903";
    --icx-arrow-right: "\e908";
    --productListTranslate: -150%;
    --percentage: -100%;
    body {
        font-family: "Cairo", sans-serif;
        height: 100%;
    }
}

.navbar {
    position: sticky;
    top: 0;
}

.SingleDatePicker_picker {
    z-index: 99999999999999999999 !important;
}

.link_action {
    font-size: 14px;
    font-weight: 500;
    margin: 0 5px;
    i {
        font-size: 20px;
    }
}

.bg-transparent {
    padding: 5px 0;
    background-color: transparent !important;
}

.modal-sm {
    max-width: 350px !important;
}

.title-modal-sm {
    font-size: 12px;
    text-align: justify;
    font-weight: 200;
}

.mt-10 {
    margin-top: 10% !important;
}

.custom-padding-15 td {
    padding: 20px 10px !important;
}

.cartItem {
    --bs-gutter-x: 1.5rem;
    background: #fff;
    padding: 10px;
}

.cartItemQuantity,
.proceed {
    background: transparent;
}

.DateInput__disabled {
    border: none;
    background: #f4f4f4 !important;
}

.product-content-items .days-box {
    width: 95px;
}

.product-country-from {
    width: 25%;
}

.product-country-to {
    width: 25%;
}

.Itinerary {
    .fa-plane::after {
        [dir="rtl"] & {
            left: 50% !important;
            right: unset;
        }
    }
}

.modal-header .close {
    [dir="rtl"] & {
        margin: 0rem 0rem !important;
        padding: 0 !important;
    }
}

.custom-table td:first-child {
    [dir="rtl"] & {
        border-top-left-radius: unset;
        border-bottom-left-radius: unset;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
    }
}

.DateInput {
    background: transparent !important;
}

.SingleDatePickerInput__disabled {
    background-color: transparent !important;
}

.main-filter {
    background-color: #fafafa;
    border-top: 1px solid #dfdfdf;
    border-bottom: 1px solid #dfdfdf;
    padding: 9px 0 !important;
}

.hotel-details .progressbar-info .btn-pay {
    position: relative;
    right: 21px;
    left: unset;
    [dir="rtl"] & {
        position: relative;
        left: 21px;
        right: unset;
    }
}

.hotel-details .progressbar-info .item-name {
    font-size: 14px;
    font-weight: 600;
    margin: 0px -25px !important;
}

.mr-3 {
    [dir="rtl"] & {
        margin-right: unset !important;
        margin-left: 1rem !important;
    }
}

.main-tabs {
    padding: 0.5rem;
    background: #fff;
    // overflow: hidden;
    border-radius: 8px !important;
    border: none;
}

.payModal .check-box-content .form-check-input {
    margin: 0 !important;
    position: relative;
    top: 6px;
    right: 4px;
}

.radios {
    display: flex;
    justify-content: center;
    margin-bottom: 25px;
    [dir="rtl"] & {
        text-align: right;
    }
}

.fixHandleCreate {
    .modal-footer {
        display: block !important;
        width: 100%;
        text-align: right;
        .btn-primary {
            float: right;
        }
        [dir="rtl"] & {
            text-align: left;
            .btn-primary {
                float: left;
            }
        }
    }
}

.boxChild {
    min-width: 320px !important;
    display: block;
}

.boxHeader {
    background: gray;
}

.flex-prepend {
    padding: 12px 18px;
    /* flex: auto; */
    border-radius: 5px;
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0;
}

.main-tabs1 {
    display: flex;
    flex-direction: row;
}

.HotelSearch label {
    text-transform: capitalize;
    font-weight: 600;
}

.no-gutters {
    width: 100%;
}

.SingleDatePicker {
    width: 100% !important;
}

.SingleDatePickerInput {
    width: 100% !important;
}

.DateInput {
    width: 100% !important;
    max-width: 100% !important;
}

.newFixPadding {
    padding: 9px 10px !important;
}

.DateInput_input {
    background: transparent !important;
}

.bg-Rooms {
    background-color: #f7f7f7;
}

.container-centerLabel {
    display: flex;
    height: 100%;
    flex-direction: row;
    align-items: center;
    flex-flow: row wrap;
}

.small-title {
    line-height: 0.8;
    font-size: 14px;
    margin-top: -9px;
    color: #979797;
}

.box-child {
    position: absolute;
    width: 100%;
    background: white;
    z-index: 20;
    bottom: 45px;
    max-height: 400px;
    overflow-y: scroll;
    padding: 25px;
    border-radius: 8px;
    box-shadow: 0 1px 3px grey;
}

.roomsNumber {
    color: #cb9a51;
    margin: 0 4px;
}

.adultNumber {
    color: #0c3b5c;
    font-size: 14px;
    font-weight: 500;
    margin: 0 4px;
}

.ageInput .control-field__body {
    padding: 6px 7px !important;
}

.ageInput .control-field {
    height: 35px !important;
}

.text-danger-custom {
    color: #ff6868;
    font-size: 14px;
}

.Soon-span {
    background: #dea237;
    background: linear-gradient(180deg, #daa249 0%, #cc9a50 100%);
    position: absolute;
    color: #fff;
    top: -15px;
    padding: 1px 5px;
    font-size: 14px;
    border-radius: 5px;
}

.search-banner {
    width: 20%;
    height: -moz-fit-content;
    height: 66vh;
    /* background-position: center; */
    background-position: center;
    background-size: cover;
}

[dir="rtl"] .search-banner {
    border-radius: 6px 0px 0px 6px;
}

[dir="ltr"] .search-banner {
    border-radius: 0px 6px 6px 0px;
}

.borderdanger {
    border: 1px solid#ff5f59 !important;
}

.new-color {
    color: #cb9a51;
}

.border-d {
    border-top: 1px solid #ebebeb;
    display: block;
}

.span-warining {
    color: gray;
    padding: 5px 15px;
    font-size: 20px;
    cursor: pointer;
}

.span-comment {
    color: gray;
    padding: 5px 15px;
    font-size: 20px;
    cursor: pointer;
}

.span-warining:hover {
    color: #ce7c0d;
}

.span-comment:hover {
    color: #ce7c0d;
}

.span-Request {
    background-color: #ce7c0d;
    padding: 4px 6px;
    color: #fff;
    border-radius: 6px;
    line-height: 0.9;
    height: 25px;
    font-size: 13px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: auto;
}

.span-Available {
    background-color: #28a745;
    padding: 4px 6px;
    color: #fff;
    border-radius: 6px;
    line-height: 0.9;
    height: 25px;
    font-size: 13px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: auto;
}

.bg-text {
    background-color: #f1f4f7;
}

.w-105 {
    width: 190px;
    display: inline-block;
}

.span-info {
    color: gray;
    cursor: pointer;
}

.span-info:hover {
    color: #ce7c0d;
    cursor: pointer;
}

.room-description {
    padding: 15px;
    width: 100%;
    background-color: #f1f4f7;
    color: #2d2d2d;
    font-weight: 500;
}

.select-room .custom-control-field {
    border: 1px solid #28a745 !important;
    background-color: #e7f5ea !important;
}

.span-count {
    float: right;
    background: #cb9a51;
    width: 23px;
    text-align: center;
    color: #fff;
    border-radius: 20px;
}

.price-model {
    margin-top: 10%;
}

.box-item {
    margin: 0 8px;
    display: inline-flex;
    width: 200px;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    position: relative;
    border: 1px solid #80808066;
    border-radius: 8px;
    padding: 15px;
    height: 160px !important;
}

.box-item label {
    color: #3b4b5d;
    font-weight: 700;
}

.box-item input[type="radio"] {
    position: absolute !important;
    left: 6px;
}

.checked-active {
    background-color: #f7f1e9;
}

.SpecialRequests .form-check {
    display: inline-flex;
    width: -moz-fit-content;
    width: fit-content;
    margin: 0;
    min-width: 50%;
}

.react-switch {
    box-shadow: 0 1px 2px grey;
}

.border-top {
    border-top: 1px solid rgba(128, 128, 128, 0.56);
}

.span-remove {
    margin-top: 20px;
    display: block;
    background: #ff6868;
    width: 20px;
    height: 20px;
    text-align: center;
    font-size: 12px;
    color: #fff;
    border-radius: 12px;
    padding: 2px;
}

.roomTypesTable {
    td {
        padding: 0 0.7rem;
        vertical-align: middle;
        border-top: 1px solid #dee2e6;
    }
    th {
        padding: 0.75rem;
        vertical-align: middle;
        color: #112e53;
    }
    .hover-roomTypesTable:hover {
        background-color: #faf2e8;
        cursor: pointer;
    }
}

.Cost-title {
    color: #0c3b5c;
    font-size: 18px;
    line-height: 0.9;
}

.price-title {
    color: #28a745;
    font-size: 20px;
    line-height: 0.9;
}

.title-terms {
    font-size: 16px;
    span {
        color: #cb9a51;
    }
}

.boxCancellation {
    text-transform: capitalize;
    padding: 8px;
    height: 62.54px;
    background: #ebebeb;
    text-align: center;
    border-radius: 6px;
    p {
        text-transform: capitalize;
    }
}

.k-market-search-container .box-Umrah-Plus {
    height: 52px;
    width: 100%;
    background-color: #ebebeb;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    padding: 15px;
    input {
        width: 20px;
        height: 20px;
        // accent-color: #b08e26;
        accent-color: #bc6221;
        color: #2d2d2d;
    }
}

.activeText {
    // color: #cb9a51 !important;
    color: #bc6221 !important;
    font-weight: bolder;
}

.btn-backNew {
    border: 1px solid;
    display: inline-flex;
    float: right;
    align-items: center;
    align-content: center;
    font-size: 14px;
}

.hotel-amenities-new {
    margin-bottom: 15px;
    background-color: #f1f4f7;
    padding: 12px 12px !important;
    box-shadow: 2px 2px 5px rgb(0 0 0 / 11%);
}

.hotel-amenities-new ul li,
.hotel-amenities-new ul li {
    background-color: transparent;
    border: none;
    padding: 5px 7px;
    display: inline-block;
    margin: 2px 0;
    width: 33%;
    i {
        color: gray;
    }
}

.mt-8 {
    margin-top: 5%;
}

.head-room {
    justify-content: space-between;
    display: flex;
    align-items: center;
    background: #f4f4f4;
    padding: 8px;
    border-radius: 4px;
}

.text-custome {
    color: #cb9a51 !important;
}

.bg-Ground {
    text-transform: capitalize;
    background-color: #e7f5ea;
    padding: 20px 10px;
    p {
        text-transform: capitalize;
        color: #28a745;
    }
}

.bg-dangerr {
    background-color: #f3d6d7;
    padding: 20px 10px;
    p {
        text-transform: capitalize;
        color: #ff6868;
    }
}

.bg-dangerNew {
    color: #ff6868;
    background-color: #f3d6d7;
    p {
        text-transform: capitalize;
        color: #ff6868;
    }
}

.text-hidden {
    visibility: hidden !important;
}

.float-custome {
    float: right;
    [dir="rtl"] & {
        float: left;
    }
}

.Text-Booking {
    .input-group {
        overflow: hidden !important;
    }
}

.listAuto {
    position: absolute;
    background: #fff;
    z-index: 9999;
    border: 1px solid #8080806e;
    border-radius: 9px;
    overflow: hidden;
    overflow-y: auto;
    max-height: 350px;
    /* top: 79px; */
    width: 100%;
    li {
        width: 100%;
        padding: 12px 7px;
        display: flex;
        border-bottom: 1px solid rgba(128, 128, 128, 0.414);
        img {
            margin: 0 4px;
        }
        &:hover {
            background-color: #0c3b5c98 !important;
            color: #fff;
            cursor: pointer;
        }
    }
}

.fix-flag {
    position: absolute;
    width: 35px;
    height: 22px;
    left: 15px;
    top: 11px;
    [dir="rtl"] & {
        left: unset;
        top: 12px;
        right: 15px;
    }
}

.show>.bell-notifaction .custom-notification img {
    filter: invert(91%) sepia(0%) saturate(0%) hue-rotate(346deg) brightness(104%) contrast(206%);
}

.fa-angle-right {
    [dir="rtl"] & {
        transform: rotateY(179deg);
    }
}

.btn.disabled,
.btn:disabled {
    background: transparent linear-gradient(180deg, #767676 0%, #979797 97%, #28a745 100%) 0% 0% no-repeat padding-box !important;
    border: none !important;
}

.fix_place_arrow {
    position: absolute;
    right: 4px;
    top: 10px;
    [dir="rtl"] & {
        right: inherit;
        left: 4px;
    }
}

input::placeholder {
    color: hsl(0, 0%, 50%) !important;
}

button {
    text-transform: uppercase !important;
}

@media print {
    .TechnicalSupport-width {
        display: none !important;
    }
}

.new-price-number {
    border: 1px solid #80808063;
    // padding: 5px 30px 0 10px;
    position: relative;
    bottom: 28px;
    width: 400px;
    background: #fff;
    /* right: 40px; */
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: end;
    margin-left: auto;
}

.btn-Product-Filter {
    padding: 10px 90px;
    background-color: #cb9a51;
    border: none;
    border-radius: 5px;
    color: white;
    float: right;
}

.title-Filter {
    font-weight: 700 !important;
    font-family: sans-serif;
    font-size: 16px !important;
}

[dir="rtl"] .title-Filter {
    font-weight: 700 !important;
    font-size: 16px !important;
    font-family: 'Cairo';
}

.header-Filter {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.btn-apply {
    background-color: #0c3b5c;
    border: none;
    border-radius: 5px;
    color: white;
    padding: 4px 9px;
    text-transform: capitalize !important;
    font-family: sans-serif;
    i {
        margin-right: 6px;
    }
    &:hover {
        color: #fff !important;
    }
}

.btn-green-300 {
    background-color: #2da94a;
    color: #ffffff;
    font-size: 16px;
    border: none;
    &:hover {
        color: #fff !important;
    }
}

.main-filter {
    background-color: #fafafa;
    box-shadow: 0px 1px 4px #adadad80;
}

.tr-hover:hover {
    background-color: #faf2e8 !important;
}
.btn-Product-Filter {
	padding: 10px 90px;
	background-color: #cb9a51;
	border: none;
	border-radius: 5px;
	color: white;
	float: right;
}
.title-Filter {
	font-weight: 700 !important;
	font-family: sans-serif;
	font-size: 16px !important;
}
.header-Filter {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.btn-apply {
	background-color: #0c3b5c;
	border: none;
	border-radius: 5px;
	color: white;
	padding: 4px 9px;
	text-transform: capitalize !important;
	font-family: sans-serif;
	i {
		margin-right: 6px;
	}
}
.main-filter {
	background-color: #fafafa;
	box-shadow: 0px 1px 4px #adadad80;
}
.tr-hover:hover {
	background-color: #faf2e8 !important ;
}
.ReactCrop__crop-selection {
    background-color: #00800056;
}

.th-trip-mang {
    color: #112e53;
    height: 55px;
    th {
        vertical-align: middle !important;
        font-weight: 700;
        border-top: 0 !important;
    }
}

.up-down {
    position: relative;
}

.tb-trip-mangment {
    border: 1px solid #eee;
}

.tb-traip-products {
    img {
        padding: 3px;
    }
}

.tr-trips-mangment {
    td:nth-child(1) {
        padding-left: 10px;
    }
    td {
        vertical-align: middle !important;
        padding: 0;
    }
}

.rotet-plane {
    transform: rotate(-45deg);
}

.add-price-underline {
    color: red;
    text-decoration: underline;
    font-size: 17px;
    font-weight: 300;
}

.btn-unpublished {
    color: white;
    width: fit-content;
    padding: 0 5px;
    background-color: #ce7c0d;
    border-radius: 5px;
}

.edit-product {
    margin-right: 20px;
    color: #ce7c0d;
    border: none;
    background-color: inherit;
    text-transform: capitalize !important;
}

.pegntion-trip:hover {
    color: white;
    background-color: #112e53;
}

.pegntion-trip {
    display: inline-block;
    padding: 0px 5px;
}

.previos-page {
    transform: rotate(180deg);
}

.page-list {
    text-align: right;
}

.btn-reset-filter {
    border: 1px solid gray;
    border-radius: 5px;
    padding: 8px 9px;
    background-color: white;
    width: 86px;
    height: 32px;
    text-align: center;
    text-transform: capitalize;
    font-family: sans-serif;
    font-size: 14px;
}

.hd-bulid-pro {
    background-color: white !important;
    margin-top: -3rem;
    height: 200px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    .bulid-pro-inputs {
        width: 90%;
        height: 115px;
        margin-top: 30px;
        align-items: stretch;
        padding-top: 20px;
        button {
            margin-top: 8px;
        }
    }
}

.back-to-trips-btn {
    background-color: white;
    color: gray;
    border: 1px solid grey;
    border-radius: 3px;
    text-transform: capitalize !important;
    padding: 2px 14px;
    i {
        padding-right: 4px;
    }
}

.builder-wrapper-content {
    border-radius: 12px;
    background-color: white !important;
    border: 1px solid #cfcfcf;
}

.circle-bg {
    background-color: #ebebeb !important;
}

.sub-add {
    position: relative;
    top: 7px;
    text-transform: capitalize;
    font-size: 14px !important;
    font-family: sans-serif;
    color: #3e3a3a !important;
}

.title-wrapper-builder {
    font-size: 16px !important;
    font-weight: 800 !important;
    color: #0c3b5c !important;
    text-transform: capitalize;
}

.empty-wrapper-bulder {
    h1 {
        font-size: 20px;
        font-weight: 300;
    }
}

.product-build__button-actions .actions-buttons-group .save-changed-p-b {
    float: right;
    margin-right: 71px !important;
    font-size: 20px;
    background: #cb9a51 !important;
    &.disabled,
    &:disabled {
        background: rgb(218, 215, 215) !important;
        color: rgb(165, 163, 163) !important;
    }
}

.bg-wrapper-builder {
    background-color: #f7f8fa !important;
}

.product-build__leftside-menu {
    .om-bg-white {
        background-color: white !important;
    }
}

.empty-box {
    width: 1155px;
    height: 555px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.blue-txt-item {
    font-size: 18px !important;
    color: #0c3b5c !important;
    text-transform: capitalize;
    font-weight: 600;
}

.htel-date {
    font-size: 14px !important;
    font-family: sans-serif;
    color: #3e3a3a !important;
}

.btn-text-wrapper {
    font-size: 20px;
    background: #cb9a51 !important;
    color: white;
}

.product-element_form {
    label {
        font-size: large;
        font-family: sans-serif;
    }
    /* 	&::placeholder {
		color: #28a745;
		font-weight: bold;
	} */
}

.product-content-items .product-arrival {
    .fa-circle {
        color: gray;
        position: relative;
        &::after {
            background-color: gray;
        }
    }
    .transitons-count {
        position: relative;
        background-color: rgb(40 167 69);
        padding: 2px 14px;
        border-radius: 5px;
        font-weight: 700;
        color: white;
        z-index: 100 !important;
        &::after {
            content: "";
            position: absolute;
            top: 10px;
            left: 60px;
            width: 75px;
            height: 1px;
            background-color: gray;
            z-index: -1 !important;
        }
    }
}

.btn-add-transit {
    color: #28a745 !important;
    font-size: 16px !important;
    text-transform: capitalize !important;
    font-family: sans-serif;
    i {
        padding: 4px;
    }
    &:disabled {
        color: #28a745 !important;
        font-size: 16px !important;
        text-transform: capitalize !important;
        font-family: sans-serif;
    }
}

.product-content-items {
    .nights-icon {
        background-color: #ebebeb;
        margin-right: 70px;
        border: 0;
        font-size: 18px !important;
        font-family: sans-serif !important;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 125px;
        height: 80px;
    }
    .nights-box {
        position: relative;
        color: #979797;
        &::after {
            content: "";
            position: absolute;
            top: 14px;
            left: 34px;
            width: 94px;
            height: 1px;
            transform: rotate(90deg);
            background-color: #dfdfdf;
        }
        &::before {
            content: "";
            position: absolute;
            top: 14px;
            right: 60px;
            width: 96px;
            height: 1px;
            transform: rotate(90deg);
            background-color: #dfdfdf;
        }
    }
}

.blue-drop-btn {
    color: #0c3b5c;
}

.gold-title {
    font-size: 22px;
    text-transform: uppercase;
    color: #cb9a51;
    font-weight: 600;
}

.black-title-builder {
    font-size: 18px;
    text-transform: capitalize;
    color: black;
    font-weight: 400;
}

.icon-pro-Itinerary {
    width: 44px;
    height: 44px;
    margin-left: 14px;
    background-color: #ebebeb !important;
    z-index: 1;
}

.txt-dis-tabs {
    font-size: 17px !important;
    color: #2d2d2d !important;
    font-weight: normal !important;
}

.all-label-filter {
    label {
        font-size: 15px !important;
        color: #2d2d2d !important;
    }
}


/* .table-update tbody td {
    color: #464646;
    font-size: 14px;

} */

.all-txt-table {
    td {
        font-size: 14px !important;
        color: #464646 !important;
        font-weight: normal;
    }
}

.hd-title-model {
    h5 {
        font-size: 16px !important;
        font-weight: 500 !important;
        color: #1b1e21 !important;
    }
}

.p-remark {
    font-size: 16px !important;
    font-weight: 400 !important;
    color: #1b1e21 !important;
    text-transform: lowercase;
    font-family: sans-serif;
}

.model-remark {
    min-width: 700px;
}

.new-fz {
    font-family: sans-serif !important;
    font-size: 20px !important;
}

.sign-up-label {
    font-size: 16px !important;
    font-family: sans-serif !important;
    button {
        font-size: 16px !important;
        font-family: sans-serif !important;
    }
    label {
        font-size: 16px !important;
        font-family: sans-serif !important;
    }
}

.wpwl-container {
    position: relative;
    background: #f4f4f4 !important;
}

.sans-font {
    font-family: sans-serif !important;
}

.font-s20 {
    font-size: 20px !important;
}

.mtFIX-2 {
    margin: 16% auto 2% !important;
}

.summary-box {
    p {
        display: flex;
        justify-content: space-between;
    }
}

.modal-header {
    h5 {
        font-size: 18px;
        font-weight: 400;
    }
}

.db-title {
    font-size: 20px !important;
    font-family: sans-serif;
    color: #0c3b5c !important;
}

.db-style {
    font-family: sans-serif;
    font-size: 16px;
    color: #0c3b5c !important;
}

.tex-warn {
    color: #cb9a51 !important;
}

.O-Email-box {
    h1 {
        font-family: sans-serif !important;
    }
    .register-email-confirmation {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
}

.x-model {
    margin-left: auto;
}

.add-guest {
    font-size: 16px !important;
    font-weight: 600 !important;
    i {
        font-size: 14px;
        width: 18px;
        height: 18px;
        line-height: 18px;
    }
}

.rememberme-label {
    font-size: 16px !important;
}

.price-text {
    font-size: 18px;
    color: #0c3b5c !important;
    font-weight: 500;
}

.old-price {
    color: red;
    font-size: 15px;
    font-weight: 500;
    /* text-decoration: wavy; */
    text-decoration-line: line-through;
}

.text-Nights {
    font-weight: 400;
}

.new-price-number a {
    width: 100%;
}

.new-price-number a:hover {
    width: 100%;
    background-color: #faf2e8 !important;
}

.custom-color,
.custom-select-text {
    color: #cb9a51 !important;
}

.custom-border-color {
    border-color: #cb9a51 !important;
    //border-color: $main-bg-sbs !important;
}

.main-nav-sbs {
    background-color: #ebebeb;
    .nav {
        background: #ebebeb;
    }
    .nav-link.active {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 58px !important;
        background: $main-bg-sbs !important;
        border-radius: 0 !important;
        border: 1px solid #dfdfdf;
        border-bottom: 0;
        color: #fff !important;
        font-weight: 700;
        span {
            color: #fff !important;
        }
    }
    .nav-item {
        min-height: 58px;
        width: 20%;
        text-align: center;
        margin-bottom: 0px !important;
        margin-top: 0px !important;
        background: #ebebeb !important;
        .nav-link {
            /* border: none; */
            color: #324356;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            min-height: 58px;
            min-width: auto !important;
            border: 0 !important;
            font-weight: 600;
            background-color: #cbcbcb;
            border-radius: 0 !important;
        }
    }
}

.code-num {
    float: left;
    direction: ltr;
}

.select_component {
    position: relative;
    width: 100%;
    border-radius: 5px;
    background-color: #ffffff;
    border: 1px solid transparent;
    border-radius: 0.3125rem;
    font-size: 12px;
    height: 44px;
}

.control-field select:not(:checked) {
    color: #000 !important;
}

.sm-taps {
    width: 28%;
    .nav.nav-tabs {
        border-radius: 8px;
        overflow: hidden;
        .nav-item {
            width: 50%;
            min-height: 41px;
            a {
                min-height: 30px !important;
            }
        }
    }
}

.offerlayout {
    position: fixed;
    right: 0;
    bottom: 0px;
    border-top: 10px solid #cc9a50;
    width: 100%;
    z-index: 555555555555;
    background: #f7f7f7;
    min-height: 0;
    .btn-toggle {
        position: absolute;
        top: -40px;
        right: 50px;
        background-color: #cc9a50;
        color: #fff;
        border: none;
        font-size: small;
        padding: 5px;
        text-transform: capitalize !important;
    }
    .btn-close {
        position: absolute;
        top: -30px;
        right: 50px;
        background-color: #cc9a50;
        color: #fff;
        border: none;
        font-size: small;
        padding: 0px 15px;
    }
    .btn-close::after {
        content: "";
        position: absolute;
        top: -10px;
        left: 0px;
        height: 0;
        width: 0;
        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
        border-bottom: 10px solid #cc9a50;
    }
    .ads-head {
        .ads-type {
            color: #c30101;
            font-size: 18px;
            font-weight: 700;
        }
        .ads-date {
            font-weight: 700;
            font-size: 16px;
            columns: #0000;
        }
    }
    .slider-row {
        position: relative;
        display: flex;
        gap: 1rem;
        display: flex;
        transition: all 250ms linear;
        -ms-overflow-style: none;
        /* hide scrollbar in IE and Edge */
        scrollbar-width: none;
    }
    .card-item {
        margin-top: 10px;
        .img-card {
            width: 250px;
            height: 115px;
        }
        .card-info {
            .name {
                font-weight: 700;
                color: #0c3b5c;
                margin-top: 10px;
                font-size: 18px;
                white-space: nowrap;
                width: 250px;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .date {
                color: #c30101;
                font-size: 13px;
            }
            .date_ar {
                color: #c30101;
                font-size: 13px;
                display: flex;
            }
            .btn-card {
                margin-top: 10px;
                text-transform: capitalize !important;
                border: 1px solid #cc9a50;
                border-radius: 5px;
                color: #cc9a50;
                padding: 2px 12px;
                margin: 5px auto !important;
                display: block;
                a {
                    color: #cc9a50 !important;
                }
            }
            .btn-card:hover {
                background-color: #cc9a50;
                color: #fff;
                a {
                    color: #fff !important;
                }
            }
        }
    }
    .react-multi-carousel-track {
        .react-multi-carousel-item {
            flex: 1 1 auto;
            position: relative;
            width: 278px;
            // width: 35% !important;
        }
    }
}

html[dir="rtl"] body .right-slider-arrow .fa-chevron-right {
    transform: rotateY(0deg);
}

.ads-date {
    direction: rtl;
}

.container_layout {
    width: calc(100% - 70px);
    margin-left: auto;
}

.light-opacity {
    opacity: 0.4;
}

.left-slider-arrow,
.right-slider-arrow {
    position: absolute;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
    width: 48px;
    height: 48px;
    border-radius: 24px;
    background-color: white;
    border: 1px solid #ddd;
}

.slider-row-wrapper {
    overflow: hidden;
    position: relative;
    width: 95%;
    height: 100%;
    margin: auto;
}

.left-slider-arrow {
    left: 10vw;
}

.right-slider-arrow {
    right: 10vw;
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    .slider-row-wrapper {
        width: 85%;
    }
    .left-slider-arrow {
        left: 1vw;
    }
    .right-slider-arrow {
        right: 1vw;
    }
}

@media (min-width: 1200px) and (max-width: 1799px) {
    .slider-row-wrapper {
        width: 95%;
        margin-left: auto;
    }
    .left-slider-arrow {
        left: 5vw;
    }
    .right-slider-arrow {
        right: 5vw;
    }
}

// @media (min-width: 1800px) and (max-width: 2560px) {
// 	.offerlayout {
// 		bottom: -120px !important;
// 	}
// }
.img-card {
    width: 100%;
}

.card-info {
    width: 100%;
}

html[dir="rtl"] {
    .container_layout {
        margin-right: auto;
        margin-left: 0;
    }
    .text-left {
        text-align: right !important;
    }
}

.lds-dual-ring {
    display: inline-block;
    width: 80px;
    height: 80px;
}

.lds-dual-ring:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid rgb(28, 43, 245);
    border-color: rgb(37, 72, 168) transparent rgb(35, 55, 235) transparent;
    animation: lds-dual-ring 1.2s linear infinite;
}

.mr-7 {
    margin-right: -7px;
}

@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.disabled-li {
    opacity: .5;
}

.closed .disabled-li.sidenav_item:hover {
    width: auto !important;
}

.closed .disabled-li .sidenav_link:hover .dash-text {
    visibility: hidden;
}

.closed .disabled-li .sidenav_link:hover .sidenav_arrow {
    visibility: hidden;
}

.closed .disabled-li .sidenav_link:hover .sidenav_dropdown {
    box-shadow: none !important
}

.disabled-li .Soon-span {
    top: 0px !important;
    font-size: 10px !important;
}

.bell-notifaction.disabled {
    background: transparent !important;
    opacity: .3;
}

.elementToFadeInAndOut {
    -webkit-animation: fadeinout 1.5s linear infinite;
    animation: fadeinout 1.5s linear infinite;
    opacity: 0;
}

@-webkit-keyframes fadeinout {
    50% {
        opacity: 1;
    }
}

@keyframes fadeinout {
    50% {
        opacity: 1;
    }
}

.market-search-guide-btn {
    position: fixed;
    right: 0;
    top: 90px;
    padding: 5px 18px;
    background: #daa249;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff;
    text-transform: capitalize!important;
}
.market-search-guide-btn:hover {
    color: #fff;
}
.market-search-guide-btn i {
    font-size: 24px;
    color: #fff;
    margin-bottom: 2px;
}
[dir="rtl"] .market-search-guide-btn {
    right: initial;
    left: 0;
}
.introjs-tooltipReferenceLayer *,.introjs-tooltipReferenceLayer {
    font-family: inherit!important;
    font-size: 14px !important;
}
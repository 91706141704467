@media (min-width: 320px) and (max-width: 576px) {
	.TechnicalSupport-width {
		img {
			width: 28.87px !important;
			display: none;
		}
		.small-support {
			display: block !important;
		}
	}
	.container_layout {
		width: 100% !important;
	}

	.navbar.bg-white.shadow {
		margin-bottom: 2px !important;
	}
	[dir="rtl"] #sideNav.closed {
		display: none !important;
	}
	.T-team-management {
		margin: 0 !important;
		padding: 0 !important;
		.main-nav-sbs {
			background-color: #f7f8fa !important;
		}
		.tab-icons {
			margin-bottom: 12px;
			.nav-tabs {
				display: flex;
				flex-direction: row !important;
			}
			ul li {
				width: 33%;
				border-inline-end: 1px solid #fff;
				.product-build-list-item {
					display: flex;
					img {
						width: 30px;
					}
					span {
						zoom: 0.8;
					}
				}
			}
		}
		.tab-content {
			width: calc(100% - 54px) !important;
			.member-head {
				border-radius: 5px 5px 0;
			}
			.controls-field {
				display: flex;
				flex-direction: row;
				[class^="col-"],
				.filter-btn {
					width: 50% !important;
				}
			}

			.manage-box.team-box {
				margin: 0;
				padding: 0;
				.product-build__product-collpase {
					margin-inline: 0px !important;
					margin-block: 5px !important;
					.button-collapse {
						margin-left: 0 !important;
						.product-content-items {
							flex-wrap: wrap !important;
							ul {
								margin-top: 7px !important;
							}
						}
					}
				}
			}
			.branch-collabse-row {
				p.text-blue-dark {
					font-size: 13px !important;
					line-height: 1.1;
				}
			}
		}
	}
	/****Dialog****/
	.fixHandleCreate.modal-team.modal-dialog {
		height: 100% !important;
		width: 92% !important;
		margin-inline: auto !important;
		margin-block: 50px !important;
		.modal-body {
			padding: 0 13px !important;
		}
		.form-group {
			[dir="rtl"] & {
				direction: rtl !important;
				text-align: right;
			}
			h6 {
				color: #0c3b5c;
				text-transform: capitalize !important;
			}
			.form-check {
				[dir="rtl"] & {
					padding-right: 0 !important;
				}
				padding-left: 0 !important;
			}
		}
	}
}

.profile-wrrapper {
	background: $gray-100;
	padding: 1rem;
	color: $dark-blue;

	.user-profile {
		background-color: $white;
		border-radius: 5px;
		overflow: hidden;
		min-height: 500px;
		.img-box {
			width: 150px;
			height: 150px;
			margin: 0.8rem auto;
			border-radius: 50%;
			overflow: hidden;
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
		.description {
			font-size: $text-caption;
			color: $dark-blue;
			padding: 0.8rem;
		}
	}
	.user-profile-details {
		background-color: $white;
		padding: 1rem;
		border-radius: 5px;
		overflow: hidden;
		min-height: 500px;
	}

	.buttons {
		display: flex;
		flex-direction: row-reverse;
		button {
			margin: 0 0.3rem;
		}
	}
}

// Company Profile
.current-package {
	display: flex;
	justify-content: space-between;
	padding: 0.8rem;
	margin: 0.5rem 0;
	border-top: 1px solid #eae8e9;
	border-bottom: 1px solid #eae8e9;
	font-size: $text-caption;
	a {
		text-decoration: underline !important;
	}
}

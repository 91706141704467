html[dir="rtl"] .col-containt {
	direction: ltr !important;
}
html[dir="ltr"] .col-containt {
	direction: rtl !important;
}
.col-containt {
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-weight: 600;
	//margin: 12px 0 !important;
	background: #deeaf3 !important;
	color: #fff !important;
	vertical-align: bottom !important;
	padding: 4px 0 !important;
	border: 1px solid #e6e6e6;
	//direction: rtl !important;
	border-radius: 5px;
	div {
		button {
			height: 100% !important;
			margin: 0 0 0 5px;
			border-radius: 0 !important;
			padding-top: 9px;
			padding-bottom: 9px;
		}
		.num {
			//display: inline-block;
			height: 40px !important;
			width: 40px !important;
			border-radius: 50%;
			background: #fff;
			color: #324356;
			text-align: center;
			font-weight: 700;
			margin: 0 8px;
			//vertical-align: middle !important;

			/**to big size background lenght **/
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
	.last {
		margin-right: 15px;
		margin-left: 0px;
		font-size: 16px !important;
		cursor: pointer;

		i {
			margin: 0px 8px !important;
			font-size: 18px !important;
		}
	}
	.col-containt-data {
		div {
			width: 100%;
			h4 {
				color: #707070;
				font-weight: 500;
				margin-bottom: 4px;
			}
			span {
				color: #324356;
				font-weight: 400;
			}
			.btn {
				border-radius: 5px !important;
				font-size: 15px;
			}
		}
	}
}
.num-content {
	color: #324356 !important;
	vertical-align: middle !important;
	/**to big size background lenght **/
	display: flex;
	justify-content: center;
	align-items: center;

	.num {
		display: inline-block;
		height: 25px !important;
		width: 25px !important;
		border-radius: 50%;
		color: #fff !important;
		text-align: center;
		font-weight: 500;
		margin: 0 10px;
		//background-color: grey !important;
		//vertical-align: bottom !important;
	}
}

.CollapsePlusIcon {
	margin-left: 0px !important;
	margin-right: 10px !important;
	margin-bottom: 0px !important;
	//	color: grey !important;
	font-size: 17px !important;
}

html[dir="rtl"] .CollapsePlusIcon {
	margin-left: 10px !important;
	margin-right: 0px !important;
	margin-bottom: 0px !important;
}

.editIcon {
	margin-left: 0px !important;
	margin-right: 15px !important;
}

html[dir="rtl"] .editIcon {
	margin-left: 15px !important;
	margin-right: 0px !important;
}

.grey {
	color: grey !important;
}

.blue {
	color: #0184fe !important;
}
.bg-blue {
	background-color: #0184fe !important;
}
.bg-grey {
	background-color: grey !important;
}
.submit-btn.footer {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: flex-end;
	padding: 0.75rem;
	border-top: 1px solid #dee2e6;
	margin: 10px 0 !important;
}
.user-model-content {
	padding: 5px 11px !important;
}
.team-model-content {
	.submit-btn.footer {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: flex-end;
		padding: 0.75rem;
		border-top: 1px solid #dee2e6;
		margin: 7px 0 !important;
	}
}

.group-container {
	background: #fff;
	padding: 15px 5px;
	border-radius: 5px;
	margin: 5px 0;
}

.group-container {
	.card {
		border: none !important;
		//background-color: transparent !important;
		width: 95% !important;
	}
	.card-body {
		padding: 0 !important;
		margin: 0 !important;
		border: none !important;
		.table {
			padding: 0 !important;
			margin: 0 !important;
			tr {
				border-radius: 8px !important;
				.action-tools {
					i {
						margin: 0 6px;
						font-size: 17px;
					}
				}
				td {
					.product-build__product-no-data {
						padding: 0 !important;
						margin: 0 !important;
						border-radius: none !important;
					}
				}
			}
		}
	}
	.table {
		border: none !important;
		border-collapse: separate;

		border-spacing: 0 5px;
		thead {
			color: #fff !important;
			tr {
				border: none !important;
				background-color: #324356 !important;
				color: #fff !important;
				overflow: hidden;
			}
			html[dir="rtl"] & {
				th:first-child {
					border-radius: 0px 8px 8px 0px !important;
					background-color: #324356 !important;
				}
			}
			html[dir="rtl"] & {
				th:last-child {
					border-radius: 8px 0px 0px 8px !important;
					background-color: #324356 !important;
				}
			}
			th:first-child {
				border-radius: 8px 0px 0px 8px !important;
				background-color: #324356 !important;
			}
			th:last-child {
				border-radius: 0px 8px 8px 0px !important;
				background-color: #324356 !important;
			}
		}
		tbody {
			tr {
				//background: #fff;
				box-shadow: 0 0 1px #7d7d7d;
			}

			html[dir="rtl"] & {
				td:first-child {
					border-radius: 0px 8px 8px 0px !important;
				}
			}
			html[dir="rtl"] & {
				td:last-child {
					border-radius: 8px 0px 0px 8px !important;
				}
			}
			td:first-child {
				border-radius: 8px 0px 0px 8px !important;
			}
			td:last-child {
				border-radius: 0px 8px 8px 0px !important;
			}
			td {
				padding: 5px !important;
			}
		}

		th {
			border: none !important;
			padding: 7px !important;
			vertical-align: middle !important;
		}
		td {
			border: none !important;
			//padding: 8px 4px !important;
			vertical-align: middle !important;
		}
	}
	.safa-wallet {
		justify-content: flex-end !important;
	}
	.view-btn {
		display: flex;
		align-items: center;
		flex-direction: row-reverse;
		div {
			width: 280px;
			display: flex;
			justify-content: space-between;
			margin: 8px 0;
			.btn {
				padding: 7px 15px !important;
			}
		}
	}
}
.bank-modal {
	margin: 25px 0 !important;
	.bank-modal-content {
		div {
			display: flex;
			margin-bottom: 7px;
		}
		span {
			color: #ac7c38;
			font-size: 17px;
			font-weight: 700;
			//font-family: "sans-serif" !important;
		}
		p {
			font-size: 15px;
			//font-family: "sans-serif";
			line-height: 1.7;
			margin: 0 6px !important;
		}
	}
}
/********************Start Modal Style***************************/
/* Recharge Modal*/
.container-custom-md-modal {
	width: 440px !important;
	margin: auto !important;
}
.container-custom-lg-modal.bank-modal {
	margin: 15px 0 !important;
	padding: 0 !important;
}
.container-custom-lg-modal {
	width: 550px !important;
	margin: auto;
}
.w-30 {
	width: 30% !important;
}
.w-70 {
	width: 70% !important;
}

.send-modal {
	min-width: 550px !important;
	position: absolute;
	left: 50%;
	top: 45%;
	transform: translate(-50%, -50%) !important;
	width: 410px !important;
	.modal-header {
		border: none;
	}
	h5 {
		font-family: sans-serif;
		font-weight: 500 !important;
	}
	.modal-head {
		font-family: sans-serif;
		font-weight: 500 !important;
		font-size: 18px;
		width: 65%;
	}
}
.recharge-Modal.modal-dialog {
	// top: 25% !important;
	position: absolute;
	left: 50%;
	top: 45%;
	transform: translate(-50%, -50%) !important;

	.modal-header {
		border: none;
		h5 {
			font-size: 16px !important;
		}
	}

	input[type="radio"] {
		width: 1.25em;
		height: 1em;
	}
	.radio-control {
		input[type="radio"] {
			margin-top: 2px;
			font-size: 19px;
		}
	}
	.radio-control {
		display: flex;
		align-items: flex-start;
	}
	.sent-request-content {
		color: #324356;
		p {
			font-weight: 600;
			font-size: 14px;
		}
	}
}
.modal-head {
	color: #324356;
	font-size: 16px;
	font-weight: 600;
}
.modal-btn {
	font-size: 15px;
	font-weight: 600;
}
.modal-btn:hover {
	background-color: #bf8d42;
	color: #ffffff;
}
/******update modals***************/
.w-15 {
	width: 15% !important;
}
.w-85 {
	width: 85% !important;
}
//SingleDatePickerInput SingleDatePickerInput_1
.date-filter {
	.control-field__label {
		display: inline !important;
		font-size: 13px;
		font-weight: 600;
		color: #2d2d2d;
		margin-bottom: 0;
		width: 100%;
	}
	input {
		margin-top: 0 !important;
	}
	.control-field {
		background-color: #ffffff;
		border: 1px solid #d9d9da;
		border-radius: 0.3125rem;
		font-size: 12px;
		height: 35px;
		margin: 0 auto !important;
	}
	.DateRangePickerInput .DateInput_input,
	.SingleDatePickerInput .DateInput_input {
		font-size: 13px !important;
		padding: 0 !important;
		line-height: 0 !important;
		width: 100%;
		z-index: 9;
		font-weight: 500;
		position: relative;
		/* background: blue !important; */
		top: unset;
		bottom: 7px;
	}
}
.bg-filter-gray {
	background-color: #f4f4f4 !important;
	// border: 1px solid #adadad80;
	border-top: 1px solid #adadad80;
	border-bottom: 1px solid #adadad80;
	padding: 8px 0;
}
.box-amount {
	background-color: #f1f1f1;
	border: 1px solid #dfdfdf;
	box-shadow: 0px 3px 2px #00000029;
	text-align: center;
	padding: 8px 0;
	border-radius: 5px;
	color: #3b4b5d;
	h6,
	p {
		font-size: 14px;
	}
	p {
		font-weight: 700;
	}
}
.box-pay-wallet {
	display: flex;
	background-color: #f7f1e9;
	border: 1px solid #cb9a51;
	box-shadow: 0px 3px 2px #00000029;
	padding: 8px 0;
	border-radius: 5px;
	color: #3b4b5d;
	img {
		width: 15%;
	}
	div {
		width: 85%;
		h6 {
			font-size: 15px;
			font-weight: 700;
		}
		p {
			font-size: 15px;
		}
	}
}
.modal-header {
	border-bottom: 1px solid #ebebeb !important;
	padding: 12px !important;
	color: #1b1e21;
	h5 {
		font-weight: 600;
	}
}
.modal-layer {
	width: 80%;
	margin: auto;
}
.recharge-img {
	h6 {
		width: 80%;
		margin: auto;
		font-weight: 400;
	}
}
.box-radio {
	display: flex;
	flex-direction: column;
	background-color: #fff;
	border: 1px solid #ebebeb;
	padding: 5px 1px;
	width: 49%;
	border-radius: 5px;
	img,
	p {
		margin: auto;
	}
	p {
		font-size: 14px;
		color: #3b4b5d;
		font-weight: 800;
		margin-top: 8px;
		margin-bottom: 15px;
	}
	input[type="radio"] {
		margin-top: 0px !important;
		font-size: 16px !important;
		width: 1.25em;
		height: 1em;
		accent-color: #b58236 !important;
		//here
		appearance: none;
		border: 2px solid #ddd;
		border-radius: 50%;
		padding: 5px !important;
		width: 15px !important;
		height: 15px !important;
	}
	input[type="radio"]:checked {
		accent-color: #b58236 !important;
		//here
		padding: 2px !important;

		background-color: #fff !important;
		border: 5px solid #b58236 !important;
	}
}
.border-active {
	background-color: #f7f1e9;
	border: 1px solid #cb9a51;
	accent-color: #b58236 !important;
}
/********************End Modal Style***************************/
.btn-remove-image {
	cursor: pointer;
	i {
		left: unset !important;
		margin-top: 4px;
	}
}
.w-95 {
	width: 95% !important;
}
.no-active {
	background-color: transparent;
}
.nav.nav-tabs {
	position: relative !important;
}
.wallet-wrraper {
	.main-nav1 {
		.nav-item {
			a {
				background-color: #fff;
				border-radius: 8px;
				height: 150px;
				align-items: flex-start;
				padding: 20px 25px;
				color: #cb9a51;
				// min-height: 180px;
				height: auto !important;
			}
			.nav-link.active {
				align-items: flex-start;
				background-color: #faf2e8 !important;
				border: 1px solid #cb9a51 !important;
				color: #cb9a51;
				padding: 20px 25px;
				flex-wrap: wrap;
				// min-height: 185px;
				height: auto !important;
				position: relative;
			}
		}
	}
}
.wallet-tabs {
	.tab-icons {
		span {
			color: #686d71;
			font-size: 18px;
			font-weight: 600;
		}
	}
}

.box-wallet-tab {
	.title-wallet {
		font-size: 20px;
		font-weight: 700;
	}
	h4 {
		color: #2d2d2d;
		font-weight: 500;
		font-size: 14px;
	}
	span {
		color: #0c3b5c;
		font-weight: 800;
		font-size: 15px;
	}
}
.text-gray-wallet {
	color: #979797 !important;
	font-size: 16px !important;
	font-weight: 400 !important;
}

.font-18 {
	font-size: 18px;
}
.w-60 {
	width: 60% !important;
}
.w-40 {
	width: 40% !important;
}
.btn-bank {
	color: #0c3b5c;
	font-size: 18px;
	font-weight: 700;
	text-transform: capitalize !important;
	text-decoration: underline !important;
}
.btn-bank:hover {
	color: #0c3b5c !important;
}
